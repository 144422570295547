const pushHistory = (history: any, e: any, newWindow?: boolean) => {
    e.preventDefault();
    // get the href and host url
    const fullUrl = e.target.href;
    const origin = e.target.origin;
    // construct href
    const href = fullUrl.replace(origin, "");;
    // navigate
    if (newWindow) {
        window.open(href, '_blank', "noopener,noreferrer",);
        return;
    }
    history.push(href);
    window.scrollTo(0, 0);
};

export default pushHistory;