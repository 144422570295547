import { api } from "./index";

//!------------------------------//
//! --- dashboard api routes --- //
//!------------------------------//

// --- user dashboard --- //
export const getDashboard = (exclude, rewardState) =>
	api.get(`/dashboard/get?exclude=${exclude}&rewardState=${rewardState}`);
export const claimReward = (contentID) =>
	api.patch(`/dashboard/redeem/reward/${contentID}?type=reward`);
export const claimLevelRewards = (contentID) =>
	api.patch(`/dashboard/redeem/level/${contentID}?type=level`);

// -- staff dashboard -- //

export const toggleRaidMode = () => api.patch("/staff/raid/switch");
export const getRaidModeStatus = () => api.get("/staff/raid/get");
export const forceEmailFetch = (target) =>
	api.get(`/staff/force/email/get/${target}`);
export const forceVerification = (target) =>
	api.patch("/staff/force/verify", target);
export const forceDeactivation = (target) =>
	api.patch("/staff/force/deactivate", target);
export const deleteStaffSession = () => api.patch("/staff/session/delete");
export const confirmStaffSession = (staffToken) =>
	api.patch("/staff/session/confirm", { staffToken });
export const devGenerateStaffToken = () => api.patch("/staff/session/generate");

//! leader and/or dev only
export const getGameAssets = (folder, pass) => api.get(`/staff/assets/${folder}?leaderPass=${pass}`, {
	responseType: 'blob',
});
export const getXpFree = (input) =>
	api.patch(`/staff/test/${input.method}/${input.amount}`);
export const createMod = (formData, pass) => api.post(`/accesspoint/mods/create?leaderPass=${pass}`, formData);