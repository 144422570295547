// imports
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

/**
 * Gametest component for testing new games
 *
 * @param {*} props
 * @returns
 */
const GameTest = () => {
	const [leavePrevention, setLeavePrevention] = useState<boolean>(false);
	const gameSourceURL = "./../../html5/gametest";
	const history = useHistory();

	// leave prevention button
	const handlePreventionChange = () => {
		setLeavePrevention((prevState) => !prevState);
		//exclamation for readability and use the new state
		!leavePrevention
			? toast.success(
					"You will now get a confirmation before closing the page.",
				)
			: toast.info("Turned off page leave prevention.");
	};

	// upon render
	useEffect(() => {
		// function for leave prevention
		const preventUnload = (e) => {
			if (leavePrevention) {
				const confirmationMessage =
					"Are you sure you want to leave this page? Any unsaved changes might be lost. ";
				e.returnValue = confirmationMessage; // Standard
				return confirmationMessage;
			}
		};

		const unblock = history.block(() => {
			// This block will be called when the user tries to leave the page within your SPA
			if (leavePrevention) {
				return "Are you sure you want to leave this page? Any unsaved changes might be lost.";
			}
		});

		// add and remove event listeners
		if (leavePrevention)
			window.addEventListener("beforeunload", preventUnload);
		if (!leavePrevention)
			window.removeEventListener("beforeunload", preventUnload);

		// cleanup
		return () => {
			unblock(); // Unregister the block when the component unmounts
			window.removeEventListener("beforeunload", preventUnload);
		};
	}, [leavePrevention, history]);

	useEffect(() => {
		// Spacebar + arrow key codes
		const preventKeydown = (e) => {
			if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
				e.preventDefault();
			}
		};
		window.addEventListener("keydown", preventKeydown, false);
		// cleanup
		return () => {
			window.removeEventListener("keydown", preventKeydown);
		};
	});

	useEffect(() => {
		document.title = "Game Test - Level Share Square";

		// target iframe
		const iframe = document.querySelector(
			".game-container",
		) as HTMLIFrameElement;

		if (iframe) {
			// prevent scroll when focussed
			iframe.focus({ preventScroll: true });
		}
	});

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12">
					<div
						className="card"
						style={{
							textAlign: "center",
							width: "fit-content",
							margin: "0 auto",
						}}>
						<div
							className="card-body"
							id="gameCard"
							style={{ borderRadius: "inherit" }}>
							<h2 className="card-title">Game Test</h2>
							<iframe
								title="Game Test"
								src={gameSourceURL}
								className="game-container"
								allowFullScreen={true}></iframe>
						</div>
					</div>
					<br />

					<div className="card" style={{ textAlign: "center" }}>
						<div
							className="card-body"
							id="gameButtons"
							style={{ borderRadius: "inherit" }}>
							<a
								href={gameSourceURL}
								target="_blank"
								rel="noreferrer">
								<button
									type="button"
									className="btn btn-primary btn-lg m-2">
									Fullscreen page
								</button>
							</a>
							<button
								type="button"
								title="Get a warning when you close the page to prevent you from losing progress when making levels."
								onClick={() => handlePreventionChange()}
								className={`btn btn-primary__${
									leavePrevention ? "special" : "danger"
								} btn-lg m-2`}>
								Level making mode&nbsp;
								{leavePrevention ? (
									<span
										className=" notranslate material-icons"
										style={{
											position: "relative",
											top: "5px",
										}}>
										check
									</span>
								) : null}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GameTest;
