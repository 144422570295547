import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import App from "./App";
import { Flip, toast, ToastContainer, ToastPosition } from "react-toastify";
import ErrorBoundary from "./ErrorBoundary";
import cosmicBackground from "./assets/images/Background_Cosmic.png";

// Make sure this is disabled during production - if needed during development, just comment this out for the moment.
disableReactDevTools();

const Root = () => {
	const [toastPosition, setToastPosition] =
		useState<ToastPosition>("bottom-left");

	// Callback for when the error boundary triggers
	const handleErrorBoundary = () => {
		setToastPosition("bottom-right");
		document.body.style.setProperty("--bg-gradient", "none"); // black page
		document.body.style.setProperty("--bg-url", `url(${cosmicBackground})`);
	};

	useEffect(() => {
		if (toastPosition === "bottom-right")
			// error notice
			toast.error(
				"Whoopsydaisies! Looks like you crashed the colours off the page.",
			);
	}, [toastPosition]);

	return (
		<>
			{/* toast message telling user what they need to know */}
			<ToastContainer
				position={toastPosition}
				hideProgressBar
				transition={Flip}
			/>
			<ErrorBoundary onError={handleErrorBoundary}>
				<App />
			</ErrorBoundary>
		</>
	);
};

const domNode = document.getElementById("root");
if (domNode) {
	const root = createRoot(domNode);
	root.render(<Root />);
}
