import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	displayRoleStyle,
	mapHighestRole,
	sortRoles,
} from "../../functions/styleAppliers";
import moment from "moment";
import { useHistory } from "react-router-dom";
import defaultAvatar from "../../assets/images/DefaultAvatar.webp";
import "./style.scss";

const HoverUserCard = ({
	user,
	cardPosition,
	setShowCard,
	setCardPosition,
	gameProperties,
}) => {
	const [barWidth, setBarWidth] = useState<number>(0);
	const [cardWidth, setCardWidth] = useState<number>(0);
	const [cardHeight, setCardHeight] = useState<number>(0);
	const [initialPosition, setInitialPosition] = useState<any>(window.scrollY);
	const [render, setRender] = useState(false);
	const cardRef = useRef<any>(null);
	const history = useHistory();

	const userLevels = user?.levels || 0;
	const userRates = user?.rates || 0;
	const userPlays = user?.levels_played || 0;
	const userFollowers = user?.followers || 0;
	const userReviews = user?.level_comments || 0;

	useEffect(() => {
		return history.listen(() => setShowCard(null));
	}, [history, setShowCard]);

	// set initial scrollY to know when to close window
	useEffect(() => {
		setInitialPosition(window.scrollY);
	}, []);

	useEffect(() => {
		if (render && barWidth === 0)
			for (let i = 0; i <= 350; i++) {
				setBarWidth(i / 3.5);
			}
	}, [render, barWidth]);

	// check for correct element positioning upon render
	useEffect(() => {
		const leftOverFlow =
			cardPosition.x - 10 - cardRef?.current?.offsetWidth / 2 < 0;
		const rightOverFlow =
			cardRef?.current?.offsetWidth / 2 + cardPosition.x + 10 >
			window.innerWidth;
		// check if the card goes offscreen on the left or right
		if (leftOverFlow || rightOverFlow)
			// adjust it to center
			return setCardPosition((prev) => ({
				x: leftOverFlow
					? 10 + cardRef?.current?.offsetWidth / 2
					: window.innerWidth -
						cardRef?.current?.offsetWidth / 2 -
						10,
				y: prev.y,
			}));

		// check if the card goes over the top of the screen
		if (cardPosition.y - cardRef?.current?.offsetHeight - 80 < 0)
			// adjust it accordingly
			return setCardPosition((prev) => ({
				x: prev.x,
				y: prev.y + cardHeight * 1.19,
			}));

		return setRender(true);
	}, [cardPosition, setCardPosition, cardHeight]);

	// functions for event listeners
	const handleClose = useCallback(() => {
		return setShowCard(null);
		//? component unmounts, USEEFFECT HOOK CLEANS EVENTS LISTENERS
	}, [setShowCard]);

	// function for handling scrolling away from the element
	const handleScrollAway = useCallback(() => {
		// make sure the initialposition is defined
		if (initialPosition === null) return;
		const offset = initialPosition - window.scrollY;
		// close the card if you scroll too far away
		if (offset >= 600 || offset <= -600) {
			return setShowCard(null);
			//? component unmounts, USEEFFECT HOOK CLEANS EVENTS LISTENERS
		}
		return;
	}, [initialPosition, setShowCard]);

	// event listeners
	useEffect(() => {
		document.addEventListener("scroll", handleScrollAway);
		document.addEventListener("click", handleClose);

		// cleanup function to trigger upon unmount, removing event listeners
		return () => {
			document.removeEventListener("click", handleClose);
			document.removeEventListener("scroll", handleScrollAway);
		};
	}, [handleClose, handleScrollAway]);

	// use ref to properly position the card
	useEffect(() => {
		if (cardRef?.current) {
			// card width and height
			setCardWidth(cardRef.current.offsetWidth);
			setCardHeight(cardRef.current.offsetHeight);
		}
	}, []);

	// Check if the user is online by checking when they last pinged the server
	const currentTime = new Date();
	const lastOnlineTime = new Date(user?.lastOnline);
	currentTime.setMinutes(currentTime.getMinutes() - 8);
	const isNowOnline =
		currentTime.getTime() - lastOnlineTime.getTime() < 15 * 60 * 1000;
	const nextRankFraction = user?.experience / user?.nextRankExperience;

	return (
		<div
			className="user-info-tooltip unselectable"
			ref={cardRef}
			onClick={(e) => e.stopPropagation()}
			style={{
				opacity: barWidth === 0 ? 0 : (barWidth - 5) / 5,
				transition: "opacity 0.4s ease-in-out",
				top: cardPosition.y - cardHeight * 1.1, // make the card appear above the cursor
				left: cardPosition.x - cardWidth / 2, // divide by 2 for middle
			}}>
			<div
				className="user-info-tooltip__banner"
				style={{ backgroundImage: "url(" + user?.banner + ")" }}></div>
			<div className="user-info-tooltip__top-left">
				<div className="user-info-tooltip__progress">
					<div
						className="user-info-tooltip__progress__bar"
						style={{
							width: nextRankFraction * barWidth + "%",
						}}
					/>
					<span className="user-info-tooltip__progress__username">
						<a
							href={`/users/${user.user_id}`}
							style={{ mixBlendMode: "screen" }}
							onClick={(e) => {
								e.preventDefault();
								setShowCard(null);
								history.push(`/users/${user.user_id}`);
							}}>
							{user?.username}
						</a>
					</span>
					<span
						className="yellow user-info-tooltip__progress__rank"
						style={{ mixBlendMode: "screen" }}>
						Rank {parseInt(user?.rank)}
					</span>
				</div>
				<div className="user-info-tooltip__roles">
					{!user?.verified ? (
						<span
							className="main-roles"
							style={displayRoleStyle({ loadValue: barWidth })}>
							Unverified
						</span>
					) : user?.mainRoles?.length <= 0 ? (
						<span
							className="main-roles"
							style={displayRoleStyle({ loadValue: barWidth })}>
							Member
						</span>
					) : (
						sortRoles(user?.mainRoles)
							?.slice(0, 3)
							?.map((role, key) => (
								<span key={key}>
									<span
										className="main-roles"
										style={{
											...displayRoleStyle({
												loadValue: barWidth,
											}),
											color: mapHighestRole(
												[role],
												"color",
											),
											borderColor: mapHighestRole(
												[role],
												"color",
											),
											boxShadow: `0 0 1px 1px ${mapHighestRole(
												[role],
												"color",
											)}`,
										}}>
										{role.replace(/ /g, "\u00A0")}
									</span>
								</span>
							))
					)}
				</div>
				<span
					className="user-info-tooltip__icon"
					style={{
						borderColor: user?.icon_outline,
						boxShadow: user?.icon_glow
							? `0 0 4px 2px ${user?.icon_glow}`
							: "none",
					}}>
					<img
						alt="User Avatar"
						className="user-info-tooltip__icon__image"
						onError={(e) => {
							// @ts-ignore
							e.target.src = defaultAvatar;
						}}
						src={user?.avatar ? user?.avatar : defaultAvatar}
					/>
				</span>
			</div>
			<div
				className="user-info-tooltip__activity"
				style={{
					backgroundImage:
						isNowOnline === true
							? user?.inGame !== null
								? "radial-gradient(circle, rgb(58, 232, 248), rgb(19, 167, 247))"
								: user?.idle
									? "radial-gradient(circle, rgb(214, 149, 9), rgb(194, 103, 46))"
									: "radial-gradient(circle, rgb(12, 153, 16), rgb(32, 105, 9))"
							: "radial-gradient(circle, rgb(77, 76, 76), rgb(28, 28, 28))",
				}}
			/>
			<div className="user-info-tooltip__bottom">
				<div className="user-info-tooltip__bottom__user-info">
					<div style={{ position: "absolute", left: "18%" }}>
						<span className=" notranslate material-icons">rocket_launch</span>
						<br />
						{userLevels}
					</div>
					<div style={{ position: "absolute", left: "34%" }}>
						<span className=" notranslate material-icons">star</span>
						<br />
						{userRates}
					</div>
					<div style={{ position: "absolute", left: "50%" }}>
						<span className=" notranslate material-icons">sports_esports</span>
						<br />
						{userPlays}
					</div>
					<div style={{ position: "absolute", left: "66%" }}>
						<span className=" notranslate material-icons">rate_review</span>
						<br />
						{userReviews}
					</div>
					<div style={{ position: "absolute", left: "82%" }}>
						<span className=" notranslate material-icons">groups</span>
						<br />
						{userFollowers}
					</div>
				</div>
				<div className="user-info-tooltip__bottom__last-online">
					<u>
						{!isNowOnline || user?.inGame === null || !user?.inGame
							? "LAST ONLINE"
							: "CURRENTLY IN-GAME"}
					</u>
					<br />
					<span>
						{isNowOnline ? (
							user?.inGame === null ? (
								<b className="yellow">NOW</b>
							) : (
								<b className="yellow">
									{
										gameProperties.find(
											(game) =>
												game?.acronym === user?.inGame,
										)?.name || "Unknown" // if none is found
									}
								</b>
							)
						) : (
							<b>
								{moment(user?.lastOnline).format("MMM D, YYYY")}
							</b>
						)}
					</span>
				</div>
			</div>
		</div>
	);
};

export default HoverUserCard;
