import React, { useEffect, useRef, useState } from "react";

const LinkRenderer = (props) => {
	const [useImageEmbed, setUseImageEmbed] = useState(false);
	const [useVideoEmbed, setUseVideoEmbed] = useState(false);
	const [useRawText, setUseRawText] = useState(false);
	const imageRef = useRef<null | HTMLImageElement>(null);
	const iframeSrc = useRef<string | null>(null);
	const decoratedHref = props.decoratedHref;
	const setHasEmbed = props?.setHasEmbed;
	const authorRank: number | string = props?.rank;
	const authorRankNum: number =
		typeof authorRank === "string" ? parseInt(authorRank, 10) : authorRank;

	const extractYouTubeVideoInfo = (url) => {
		const youtubeRegex =
			/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]+)(?:.*?[?&]t=([0-9]+))?/;
		const match = url.match(youtubeRegex);
		if (!match) return [null, null];

		const videoID = match[1];
		const startTime = match[2] ? parseInt(match[2], 10) : null;

		return [videoID, startTime];
	};

	useEffect(() => {
		// prevent linkification if nolink=1
		if (
			decoratedHref?.includes("&unlink=1") ||
			decoratedHref?.includes("?unlink=1")
		)
			return setUseRawText(true);
		// only embed if the author is rank 8 or above
		if (authorRankNum < 8 && authorRank !== "bypass") return;
		const image = new Image();

		// skip embedding if embed=0
		if (
			decoratedHref?.includes("&embed=0") ||
			decoratedHref?.includes("?embed=0")
		)
			return;

		// Handle image embedding
		image.src = decoratedHref;
		image.onload = () => {
			setUseImageEmbed(true);
			if (!imageRef.current) imageRef.current = image;
			if (setHasEmbed) setHasEmbed(true);
		};
		image.onerror = () => {
			setUseImageEmbed(false);
		};

		// Youtube video embeds
		if (
			decoratedHref.includes("youtube.com") ||
			decoratedHref.includes("youtu.be")
		) {
			const [videoID, start] = extractYouTubeVideoInfo(decoratedHref);
			if (videoID && !iframeSrc.current) {
				const appendString = start ? `?start=${start}` : "";
				iframeSrc.current = `https://www.youtube.com/embed/${videoID}${appendString}`;
				setUseVideoEmbed(true);
				if (setHasEmbed) setHasEmbed(true);
			}
			return;
		}

		// vimeo embeds
		if (decoratedHref.includes("vimeo.com") && !iframeSrc.current) {
			iframeSrc.current = decoratedHref.replace(
				"vimeo.com",
				"player.vimeo.com/video",
			);
			if (setHasEmbed) setHasEmbed(true);
			return setUseVideoEmbed(true);
		}
		// streamable embeds
		if (decoratedHref.includes("streamable.com") && !iframeSrc.current) {
			iframeSrc.current = decoratedHref.replace(
				"streamable.com",
				"streamable.com/e",
			);
			if (setHasEmbed) setHasEmbed(true);
			return setUseVideoEmbed(true);
		}

		return () => {
			setUseImageEmbed(false);
			setUseVideoEmbed(false);
			imageRef.current = null;
			iframeSrc.current = null;
		};
	}, [decoratedHref, setHasEmbed, authorRank, authorRankNum]);

	// don't turn into a link if unlink=1
	if (useRawText) {
		let splitSym = "";
		// loop through the decorated text
		for (let i = 0; i < props.decoratedText.length; i++) {
			if (["&", "?"].includes(props.decoratedText[i])) {
				splitSym = props.decoratedText[i];
				break;
			}
		}
		// split
		const rawText = props.decoratedText.split(splitSym);
		return <span>{rawText[0]}</span>;
	}

	// embed an image
	if (useImageEmbed && imageRef.current)
		return (
			<img
				src={imageRef?.current?.src}
				alt={props.decoratedText}
				className="linkify-embed"
			/>
		);

	// embed a youtube video
	if (useVideoEmbed && iframeSrc.current)
		return (
			<iframe
				src={iframeSrc.current}
				title={props.decoratedText}
				className="linkify-video"
			/>
		);
	// default behaviour for links
	return (
		<a
			href={decoratedHref}
			target="_blank" // open links in a new tab
			rel="noopener noreferrer">
			{props.decoratedText}
		</a>
	);
};

export default LinkRenderer;
