// react imports
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

// Import UserContext that contains the latest user information from the database
import { UserContext } from "../../App";

// react-bootstrap components used
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

// page styles
import "./style.scss";

import {
	verifyUser,
	resendVerificationMail,
	fetchEmail,
	changeEmail,
	downloadAgreement,
} from "../../api";

// constants
import { LocalStorageConstants } from "../../constants";

// types
import { FormData } from "./types";
import pushHistory from "../../functions/pushHistory";

/**
 * Let the user know they have to check their email for a verification link (or send another one).
 *
 * @param {*} props
 * @returns
 */
const Verify = (props) => {
	// Obtain the current user data (from React UserContext), passed from the App component
	const currentUserData = useContext<any>(UserContext);
	const history = useHistory();

	// Form data (user ID is known already, the user just has to pass in the verification string).
	const [formData, setFormData] = useState<FormData>({
		userID: currentUserData?._id,
		verifyString: "",
	});

	// Modal that shows up for when the user wants to resend the verification email
	const [resendVerificationMailModalShow, setVerificationMailModalShow] =
		useState<boolean>(false);

	// ReCAPTCHA status (filled out or not, has to be done before resending emails)
	const [ReCAPTCHAComplete, setReCAPTCHAComplete] = useState<boolean>(false);

	useEffect(() => {
		document.title = "Verify - Level Share Square";
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		verifyUser(formData).then((response) => {
			if (response?.status === 201) {
				localStorage.setItem(
					LocalStorageConstants.Toasts.MESSAGE,
					"Your account has been successfully verified, we hope you enjoy your time on the square!",
				);
				// Refresh the page
				window.location.reload();
			}
		});
	};

	// This fires when the ReCAPTCHA has successfully been ticked.
	async function handleCaptchaUpdate(value) {
		// Communicate to the backend to perform a second check
		await axios
			.get("/api/recaptcha/", {
				params: { token: value },
			})
			.then((response) => {
				// If True is returned, ReCAPTCHA validation has succeeded, and the registration form can be submitted
				if (response.data === true) {
					setReCAPTCHAComplete(true);
				}
			});
	}

	const handleCaptchaExpired = () => {
		setReCAPTCHAComplete(false);
		toast.warn("The captcha has expired, you'll need to revalidate it.");
	};
	// Confirmation dialog for resending the verification code to the user's email
	const resendVerificationMailModal = () => {
		return (
			<Modal
				show={resendVerificationMailModalShow}
				onHide={() => setVerificationMailModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span style={{ backgroundColor: "rgba(71, 181, 255, 0.42)" }}>
					<Modal.Body>
						<div style={{ textAlign: "center" }}>
							<h4>
								Before we send you a new email - be sure to
								check your spam folder, as our mails may have
								ended up there.
							</h4>
							<div className="captcha">
								<ReCAPTCHA
									sitekey="6LezV9ElAAAAAEm93SBxKkO6-ZFiKaewaqfTWHUt"
									onChange={handleCaptchaUpdate}
									style={{ margin: "0 auto" }}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className="mx-auto">
						<div style={{ margin: "0 auto" }}>
							<Button
								className="btn-primary__special"
								onClick={() => {
									if (ReCAPTCHAComplete) {
										toast.info("Hold up...");
										setVerificationMailModalShow(false);
										resendVerificationMail();
										setReCAPTCHAComplete(false);
									} else {
										toast.error(
											"Please fill out the ReCAPTCHA.",
										);
									}
								}}>
								Resend email
							</Button>
							&nbsp;
							<Button
								onClick={() =>
									setVerificationMailModalShow(false)
								}>
								Close
							</Button>
						</div>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	//! code relating to changing mail and validating it
	// state handlers
	const [emailValidationModalShow, setEmailValidationModalShow] =
		useState<boolean>(false);
	const [targetValue, setTargetValue] = useState<string>("");
	const [emailResult, setEmailResult] = useState<any>("");
	const [newEmail, setNewEmail] = useState<string>("");
	const [confirmEmail, setConfirmEmail] = useState<string>("");

	// handle submitting the form for checking email
	const handleEmailFetch = (e) => {
		if (e.type === "click" || e.key === "Enter") {
			if (targetValue === "")
				return toast.error(
					"You need to input your password to view your email.",
				);
			if (!ReCAPTCHAComplete)
				return toast.error("Please fill out the ReCAPTCHA.");

			toast.info("Checking password...");
			// backend request
			fetchEmail({ targetValue })
				.then((response) => {
					// show email to user
					setEmailResult(response?.data?.email);
					setTargetValue("");
					setTimeout(() => {
						// 1 minute timeout
						toast.warn(
							"1 minute timeout expired, your email has been hidden, re-enter your password to view it again.",
						);
						setEmailResult("");
						setTargetValue("");
					}, 60 * 1000);
				})
				.finally(() => setReCAPTCHAComplete(false));
		}
	};

	// email change function
	const handleEmailChange = () => {
		if (newEmail === "" || confirmEmail === "")
			return toast.error("Please fill in all fields!");

		if (targetValue === "")
			return toast.error(
				"You need to input your password to change your email.",
			);

		toast.info("Processing...");
		// backend request
		changeEmail({
			targetValue,
			newEmail,
			confirmEmail,
		})
			.then(() => {
				setEmailResult("");
				setTargetValue("");
				setNewEmail("");
				setConfirmEmail("");
				setEmailValidationModalShow(false);
			})
			.finally(() => setReCAPTCHAComplete(false));
	};

	// accompanied modal
	const emailValidationModal = () => {
		return (
			<Modal
				show={emailValidationModalShow}
				onHide={() => setEmailValidationModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span style={{ backgroundColor: "rgba(71, 181, 255, 0.42)" }}>
					<Modal.Body
						style={{ maxHeight: "70vh", overflowY: "auto" }}>
						<div style={{ textAlign: "center" }}>
							<h4>Check your email and/or change it.</h4>
							<div className="captcha">
								<ReCAPTCHA
									sitekey="6LezV9ElAAAAAEm93SBxKkO6-ZFiKaewaqfTWHUt"
									onChange={handleCaptchaUpdate}
									onExpired={handleCaptchaExpired}
									style={{ margin: "0 auto" }}
								/>
							</div>
							<div>
								<div
									style={{ marginTop: "6px", width: "100%" }}>
									<input
										type="text"
										className="access-form"
										maxLength={50}
										style={{
											borderRadius: "8px 0 0 8px",
											width: "60%",
										}}
										placeholder="Password"
										onKeyDown={(e) => handleEmailFetch(e)}
										value={targetValue}
										onChange={(e) =>
											setTargetValue(e.target.value)
										}
									/>
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id="checkEmail">
												Check your email
											</Tooltip>
										}>
										<button
											className="btn btn-primary"
											style={{
												marginBottom: "1px",
												height: "31px",
												width: "36px",
												borderRadius: "0 8px 8px 0",
											}}
											onClick={(e) =>
												handleEmailFetch(e)
											}>
											<span
												className=" notranslate material-icons"
												style={{
													position: "relative",
													bottom: "5px",
													right: "9px",
													fontSize: "30px",
												}}>
												key
											</span>
										</button>
									</OverlayTrigger>
								</div>
								{emailResult ? (
									<span className="yellow">
										{emailResult}
									</span>
								) : (
									"Input your password above to check your linked email."
								)}
								<hr
									style={{
										marginTop: "8px",
										marginBottom: "-4px",
									}}
								/>
								{/* New email */}
								<div className="form-group">
									<label
										htmlFor="password"
										className="label-adjust">
										New Email
									</label>
									<input
										className="form-control"
										maxLength={100}
										value={newEmail}
										onChange={(e) =>
											setNewEmail(e.target.value)
										}
									/>
								</div>
								{/* Confirm new email */}
								<div className="form-group">
									<label
										htmlFor="passwordConfirm"
										className="label-adjust">
										Confirm New Email
									</label>
									<input
										className="form-control"
										maxLength={100}
										value={confirmEmail}
										onChange={(e) =>
											setConfirmEmail(e.target.value)
										}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className="mx-auto">
						<div style={{ margin: "0 auto" }}>
							<Button
								className="btn-primary__special"
								onClick={() => {
									if (!ReCAPTCHAComplete)
										return toast.error(
											"Please fill out the ReCAPTCHA.",
										);

									handleEmailChange();
								}}>
								Change email
							</Button>
							&nbsp;
							<Button
								onClick={() =>
									setEmailValidationModalShow(false)
								}>
								Close
							</Button>
						</div>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	return (
		<>
			<div className="container mt-4">
				<div className="col-12 col-lg-9 mx-auto">
					<div className="card">
						<div className="card-header">
							<img
								src={props.images.Exclamation}
								alt="Exclamation"
								width="48"
								height="48"
							/>
							<span
								className="align-middle"
								style={{
									paddingLeft: "7px",
									fontSize: "32px",
								}}>
								Verification
							</span>
						</div>
						<div className="card-body">
							{/* When the user is not verified*/}
							{!currentUserData?.verified &&
								currentUserData?._id && (
									<VerificationMessage
										setVerificationMailModalShow={
											setVerificationMailModalShow
										}
										setEmailValidationModalShow={
											setEmailValidationModalShow
										}
										handleSubmit={handleSubmit}
										setFormData={setFormData}
									/>
								)}

							{/* When the user is already verified */}
							{currentUserData?.verified && (
								<div className="col-12">
									<h2>You're verified!</h2>
									<p>
										We hope you'll have a good time on Level
										Share Square!
									</p>
									<button
										onClick={() => history.push("/")}
										className="btn btn-primary">
										Home
									</button>
								</div>
							)}
							{/* When the users isn't logged in */}
							{!currentUserData?._id && (
								<div
									style={{
										display: "flex",
										flexFlow: "column",
										justifyContent: "center",
										textAlign: "center",
									}}>
									<h2>Seems like you're not logged in</h2>
									<p>
										You cannot access this feature until you
										log into your account. Trouble logging
										in? Make sure to visit our contact page
										to get in touch with us!
									</p>
									<span
										style={{
											display: "flex",
											gap: "12px",
											justifyContent: "center",
										}}>
										<button
											onClick={() =>
												history.push(
													"/auth?url=/verify",
												)
											}
											className="btn btn-primary">
											Login into your account
										</button>
										<button
											onClick={() =>
												history.push("/contact")
											}
											className="btn btn-primary">
											Contact us
										</button>
									</span>
								</div>
							)}
						</div>
					</div>
					<br />
				</div>
			</div>
			{resendVerificationMailModal()}
			{emailValidationModal()}
		</>
	);
};

/**
 * Displays a verification message and form for users who need to verify their account.
 *
 * This component shows different messages and options based on the user's parental control status.
 * If the user is not under parental control, it allows them to input a verification code, resend
 * a verification email, or change/validate their email. It also provides information about the
 * platform and the verification process for users with accounts under parental control.
 *
 * @param {Function} handleSubmit - Function to handle the form submission for verification.
 * @param {Function} setFormData - Function to update the form data state.
 * @param {Function} setVerificationMailModalShow - Function to show the modal for resending verification email.
 * @param {Function} setEmailValidationModalShow - Function to show the modal for changing or validating email.
 */

const VerificationMessage = ({
	handleSubmit,
	setFormData,
	setVerificationMailModalShow,
	setEmailValidationModalShow,
}) => {
	const currentUserData = useContext(UserContext);
	const parentalControl = currentUserData?.subRoles?.includes("Guarded");
	const history = useHistory();

	if (!parentalControl)
		return (
			<div className="col-12 center-textoutput">
				<h2>Almost done!</h2>
				<p
					style={{
						margin: "0 auto",
					}}>
					Please check your email for a verification code.
					<br /> Enter the code below to verify your account.
					<br />
				</p>
				<form onSubmit={handleSubmit}>
					{/* select level game */}
					<div className="form-group" style={{ marginTop: "8px" }}>
						{/* level name */}
						<input
							type="text"
							className="form-control"
							placeholder="Insert code given in e-mail"
							id="name"
							maxLength={16}
							onChange={(e) =>
								setFormData((prev) => ({
									...prev,
									verifyString: e.target.value,
								}))
							}
						/>
					</div>
					<br />

					{/* submit code */}
					<div
						style={{
							margin: "0 auto",
							width: "max-content",
						}}>
						<button type="submit" className="btn btn-primary">
							Submit
						</button>
					</div>
				</form>
				<hr />
				{/* Buttons for verification help */}
				<p>Didn't receive an email?</p>
				<a
					href="#!"
					className="btn btn-primary"
					style={{
						marginLeft: "6px",
						marginTop: "6px",
					}}
					onClick={(e) => {
						e.preventDefault();
						setVerificationMailModalShow(true);
					}}>
					Resend verification email
				</a>
				<a
					href="#!"
					className="btn btn-primary"
					style={{
						marginLeft: "6px",
						marginTop: "6px",
					}}
					onClick={(e) => {
						e.preventDefault();
						setEmailValidationModalShow(true);
					}}>
					Change or validate email
				</a>
				<a
					className="btn btn-primary"
					href="/contact"
					style={{
						marginLeft: "6px",
						marginTop: "6px",
					}}>
					Help/Contact
				</a>
			</div>
		);

	/**
	 * Downloads the Coppa agreement file in the desired format.
	 *
	 * The function is called when the user clicks one of the download links.
	 * It prevents the default link action, extracts the desired file type from the
	 * id attribute of the link, and calls the downloadAgreement API to fetch the
	 * file. The file is then saved to the user's computer using the Blob API.
	 *
	 * @param {Event} e - The click event.
	 */
	const handleDownloadAgreement = (e) => {
		e.preventDefault();
		const id: string = e.target.id;
		const type: string = id.split("-")[1];
		downloadAgreement(type)
			.then((res) => {
				if (!res?.data) return toast.error("Server returned no files.");
				// get the blob
				const blob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const url = window.URL.createObjectURL(blob);
				// Create a temporary download link
				const a = document.createElement("a");
				a.href = url;
				a.download = `Level_Share_Square_Coppa_Agreement.${type}`;
				document.body.appendChild(a);
				a.click(); // Click the download link
				document.body.removeChild(a);

				// Clean up
				window.URL.revokeObjectURL(url);
			})
			.catch(async (err) => {
				const blob = new Blob([err.response.data], {
					type: "application/zip",
				});
				const result = await blob.text();
				toast.error(
					err.response.status !== 500
						? JSON.parse(result)?.responseMessage
						: "Could not find this file on the LSS servers.",
				);
			});
	};

	return (
		<div className="col-12">
			<h1 className="yellow">Welcome to the site!</h1>
			<p
				style={{
					margin: "0 auto",
				}}>
				Since you're creating an account for a minor in your care, we
				require your consent before any levels can be shared using this
				account. Level Share Square is a family friendly site that
				doesn't have any nasty advertisements nor do we sell your data
				to third parties. Our goal? Expressing the art of level design
				and having fun time with (web) games!
				<h2 className="yellow">What to expect from LSS</h2>
				<ul>
					<li>
						Levels in (fan)games can be saved, shared and rated.
					</li>
					<li>
						Level Share Square is a fully free to use platform to
						learn about level design. We will never sell your data
						NOR host any monetary on-platform transactions.
					</li>
					<li>
						There is actively ongoing content moderation on our
						platform, your dependent won't encounter any NSFW
						content/swearing during their time on the square.
					</li>
					<li>
						Accounts under parental control <u>can't</u> communicate
						with other users by any means. Any social interaction
						from other users towards your dependent will also be
						limited to comments on their levels.
					</li>
					<li>
						You can check any primary site activity (levels & rates)
						on the profile page when clicking the rocket or the
						star.
					</li>
					<li>
						We encourage your dependent to have fun while learning
						about level design and the internet. Make sure to check
						in with them once in a while so they can happily show
						you their wildest creations!
					</li>
				</ul>
				<h2 className="yellow">How to verify</h2>
				Since - by law - we require parental consent in order for your
				dependent to use our service, we'll need to manually verify your
				account. We ask of you to provide your signature on the COPPA
				agreement form, granting consent for us to:
				<ul>
					<li>
						Create a profile for your dependent to store (public)
						user generated content and/or site interactions such as:
						levels, rates, favourites, avatar icons, etc.
					</li>
					<li>
						Store data with functional and/or security related
						purposes according to our{" "}
						<a
							href="/tos#section6"
							onClick={(e) => pushHistory(history, e, true)}>
							privacy policy
						</a>
						, this includes: your registered username, email, hashed
						password, ip address, etc.
					</li>
				</ul>
				<h3 className="gloamvitiation-orange">COPPA agreement forms</h3>
				Please download and fill in one of the COPPA agreement forms
				below (available in PDF and Word format) and sign it with your
				digital signature OR provide an image of your physical
				signature.
				<div
					className="d-flex"
					style={{ gap: "8px", marginTop: "8px" }}>
					<button
						className="btn btn-block btn-primary"
						id="download-pdf"
						onClick={handleDownloadAgreement}>
						Download as PDF
					</button>
					<button
						className="btn btn-block btn-primary"
						onClick={handleDownloadAgreement}
						id="download-docx">
						Download as DOCX (Word)
					</button>
				</div>
				<br />
				<h3 className="gloamvitiation-orange">Send us an email</h3>
				Once you have filled out the form, send it to us via email and
				we'll get back to you as soon as possible. We usually respond
				within 24-48 hours, though it may be within just a few hours if
				we can manage to do so. You can mail us at:&nbsp;
				<a href="mailto://levelsharesquare@gmail.com">
					levelsharesquare@gmail.com
				</a>
				<br /> <br />
				If you have any questions, you're free to include those in your
				mail as well!
				<br />
				-Team LSS
			</p>
			<hr />
			{/* Buttons for verification help */}
			<p>Signed up with the wrong email?</p>
			<a
				href="#!"
				className="btn btn-primary"
				style={{
					marginLeft: "6px",
					marginTop: "6px",
				}}
				onClick={(e) => {
					e.preventDefault();
					setEmailValidationModalShow(true);
				}}>
				Change or validate email
			</a>
		</div>
	);
};

export default Verify;
