import React from "react";
import { toast } from "react-toastify";

interface ErrorBoundaryProps {
	onError?: () => void; // Callback when an error is caught
}

interface ErrorBoundaryState {
	hasError: boolean;
	error: Error | null;
	errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		};
	}

	static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
		// Update state to show fallback UI
		return { hasError: true, error };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		// Log the error to the console
		console.error("Error caught by ErrorBoundary:", error, errorInfo);

		// Update the state with the error details
		this.setState({ error, errorInfo });

		// Trigger the onError callback if provided
		if (this.props.onError) {
			this.props.onError();
		}
	}

	render() {
		if (this.state.hasError) {
			const errorMessage = this.state.error?.message || "Unknown error";
			const errorInfo =
				this.state.errorInfo?.componentStack ||
				"No stack trace available";

			const isChunkError = /Loading chunk \d+ failed\./.test(errorMessage);

			return (
				<div
					style={{
						padding: "12px",
						width: "100%",
					}}>
					<h1>I have a feeling we're not on the square anymore...</h1>
					<p>
						Please report this error on the feedback page if needed
						(PLEASE DON'T REPORT CHUNK ERRORS, A REFRESH WILL FIX
						THOSE!).<br/> If this error is preventing access to the
						site, please contact us at{" "}
						<a href="mailto://levelsharesquare@gmail.com">
							levelsharesquare@gmail.com
						</a>
					</p>
					<hr />
					{/* Display the error and stack trace */}
					<div
						style={{
							whiteSpace: "pre-wrap", // Preserves line breaks and wraps text
							wordWrap: "break-word", // Breaks long strings into new lines
							overflowWrap: "break-word", // Modern alternative to wordWrap
							maxWidth: "100%", // Ensures it stays within the parent container
							boxSizing: "border-box", // Accounts for padding and borders
						}}>
						<h2 style={{color: "red"}}>Error: {errorMessage}</h2> 
						<br />
						<strong className="yellow">Stack Trace:</strong>
						<span className="gray">{errorInfo}</span>
					</div>
					<hr />
					<div
						style={{
							display: "flex",
							gap: "4px",
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "center",
						}}>
						<button
							className={"btn btn-primary" + (isChunkError ? "__special" : "")}
							onClick={() => window.location.reload()}>
							Refresh {isChunkError ? "(Recommended)" : ""}
						</button>
						<button
							className="btn btn-primary"
							onClick={() => {
								navigator.clipboard.writeText(
									errorMessage + "\n" + errorInfo,
								);
								toast.info("Copied error to clipboard!");
							}}>
							Copy error
						</button>
						<button
							className={"btn btn-primary" + (isChunkError ? "" : "__special")}
							onClick={() => {
								navigator.clipboard
									.writeText(errorMessage + "\n" + errorInfo)
									.then(() => {
										window.location.href =
											"/report?type=feedback";
									});
							}}>
							{isChunkError ? "Please don't report chunk errors (chunk error detected)" : "Copy error & redirect to feedback page"}
						</button>

						<button
							className="btn btn-discord"
							style={{ marginLeft: "0px" }}
							onClick={(e) => {
								e.preventDefault();
								window.open(
									"https://discord.gg/GA48Y5TXwS",
									"_blank",
								);
							}}>
							Get support on our discord
						</button>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
