import React, { useContext } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import { reactAnnouncement } from "../../api";

// constants
import { UserConstants } from "../../constants";

const AnnouncementReact = ({ announcement, setAnnouncement, single }) => {
	const currentUserData = useContext<any>(UserContext);
	const userIsMuted = currentUserData?.mainRoles.includes(
		UserConstants.Punishments.MUTED
	);
	const userIsRateRestricted = currentUserData?.mainRoles.includes(
		UserConstants.Punishments.RATE_RESTRICTED
	);

	// handling like/dislike
	const handleReactAnnouncement = (mode) => {
		if (
			announcement &&
			(!currentUserData?._id || userIsMuted || userIsRateRestricted)
		)
			return toast.error(`${
				!currentUserData?._id
					? "You must log in to do that."
					: userIsMuted
					? "You can't like/dislike announcements while muted."
					: userIsRateRestricted
					? "You can't like/dislike announcements while rate restricted."
					: "Error processing your request."
			}
		`);
		//
		if (["dislike", "like"].includes(mode) && announcement) {
			// return a toast error if the author is the current user
			if (announcement?.author === currentUserData?._id)
				return toast.error(
					"You cannot react to your own announcement!"
				);
			// make the backend call
			const commentID = announcement?._id;
			reactAnnouncement(commentID, mode).then((response) => {
				const announcementResponse = response?.data?.announcement;
				// update state of singular
				if (single === true)
					return setAnnouncement((prevState) => {
						return {
							...prevState,
							user_likes: announcementResponse?.user_likes,
							user_dislikes: announcementResponse?.user_dislikes,
						};
					});
				// update state of multiple
				if (single === false)
					return setAnnouncement((prevAnnouncements) =>
						prevAnnouncements?.map((prevAnnouncement) => {
							if (
								prevAnnouncement?._id ===
								announcementResponse?._id
							)
								return announcementResponse;
							return prevAnnouncement;
						})
					);
			});
		}
	};

	const isDisliked = announcement?.user_dislikes?.includes(
		currentUserData?._id
	);
	const isLiked = announcement?.user_likes?.includes(currentUserData?._id);

	return (
		<>
			<div className="comment-footer__react">
				<button
					className={`comment-footer__react__button${
						isLiked ? "__active-up" : ""
					}`}
					onClick={() => handleReactAnnouncement("like")}>
					<span className={`comment-footer__react__button__symbol`}>
						<span
							className={
								isLiked
									? ` notranslate material-icons`
									: ` notranslate material-symbols-outlined`
							}>
							thumb_up
						</span>
					</span>
					<span className="comment-footer__react__button__number">
						{announcement?.user_likes?.length || 0}
					</span>
				</button>

				<button
					className={`comment-footer__react__button${
						isDisliked ? "__active-down" : ""
					}`}
					onClick={() => handleReactAnnouncement("dislike")}>
					<span className={`comment-footer__react__button__symbol`}>
						<span
							className={
								isDisliked
									? ` notranslate material-icons`
									: ` notranslate material-symbols-outlined`
							}>
							thumb_down
						</span>
					</span>
					<span className="comment-footer__react__button__number">
						{announcement?.user_dislikes?.length || 0}
					</span>
				</button>
			</div>
		</>
	);
};

export default AnnouncementReact;
