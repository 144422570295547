import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./style.scss";

const Paginate = (props) => {
	const [pageInput, setPageInput] = useState<any>("");
	const history = useHistory();

	useEffect(() => {
		if (!props.hintpage) props.setContentChanged(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props.page,
		props.reviewpage,
		props.ratepage,
		props.threadpage,
		props.friendspage,
		props.inreqpage,
		props.outreqpage,
		props.blockpage,
		props.infractionpage,
		props.favepage,
	]);

	const handlePageInputChange = (e) => {
		setPageInput(e.target.value);
	};

	/* send to sub-page if integer & existing page by handling the submit */
	const handlePageSubmit = (e) => {
		e.preventDefault();

		const hasNonNumericCharacters = /\D/.test(pageInput);

		if (hasNonNumericCharacters === true) {
			setPageInput("");
			return toast.error("Please input a number!");
		}

		const parsedInput = parseInt(pageInput);

		// check for integer
		if (isNaN(parsedInput) || !Number.isInteger(parsedInput)) {
			toast.error("Please input a number!");
			// check for existing page
		} else if (
			pageInput > props.numberOfPages ||
			pageInput <= 0 ||
			pageInput > props.numberOfThreadPages
		) {
			toast.error(`Page ${pageInput} doesn't exist.`);
		} else {
			const currentUrlSearchParams = new URLSearchParams(
				window.location.search
			);
			// parse input to string and history push to the correct page
			switch (props.type) {
				case "comment":
					currentUrlSearchParams.set("page", parsedInput.toString());
					break;
				case "reply":
					currentUrlSearchParams.set(
						"threadpage",
						parsedInput.toString()
					);
					break;
				case "friend":
					currentUrlSearchParams.set(
						"friendspage",
						parsedInput.toString()
					);
					break;
				case "inreq":
					currentUrlSearchParams.set(
						"inreqpage",
						parsedInput.toString()
					);
					break;
				case "outreq":
					currentUrlSearchParams.set(
						"outreqpage",
						parsedInput.toString()
					);
					break;
				case "blocklist":
					currentUrlSearchParams.set(
						"blockpage",
						parsedInput.toString()
					);
					break;
				case "infraction":
					currentUrlSearchParams.set(
						"infractionpage",
						parsedInput.toString()
					);
					break;
				case "faves":
					currentUrlSearchParams.set(
						"favepage",
						parsedInput.toString()
					);
					break;
				case "review":
					currentUrlSearchParams.set(
						"reviewpage",
						parsedInput.toString()
					);
					break;
				default:
					// Handle the default case here, if needed
					break;
			}

			const newUrlSearchParams = currentUrlSearchParams.toString();

			history.push(`${window.location.pathname}?${newUrlSearchParams}`);
		}
		// clear input
		setPageInput("");
	};

	/* set the styling theme for the pagination buttons */
	const paginationTheme = createTheme({
		components: {
			MuiPagination: {
				styleOverrides: {
					root: {
						"& .Mui-selected": {
							backgroundColor: "#FF5722",
							color: "#fc83c1",
							border: "1px solid #fc83c1",
						},
					},
				},
			},
		},
	});

	const isSmallSize = window.location.pathname.includes("/users/");
	const paginationSize = isSmallSize ? "small" : "large";

	/* pagination buttons */
	if (props.type === "comment") {
		return (
			<ThemeProvider theme={paginationTheme}>
				<div className="pagination-container">
					<Pagination
						count={props.numberOfPages}
						page={Number(props.page) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={props.siblingCount}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set("page", `${item.page}`);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();

							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											fontPalette: "white",
											borderColor: "white",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19.5px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		);
	}

	if (props.type === "reply") {
		return props.showReplies !== false ? (
			<ThemeProvider theme={paginationTheme}>
				<div
					className={`pagination-container ${
						props.showReplies !== false ? "" : "vanish"
					}`}>
					<Pagination
						count={props.numberOfThreadPages}
						page={Number(props.threadpage) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={2}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set(
								"threadpage",
								`${item.page}`
							);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();
							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											borderColor:
												"rgba(255, 255, 255, 0.8)",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		) : null;
	}

	if (props.type === "friend") {
		return props.showReplies !== false ? (
			<ThemeProvider theme={paginationTheme}>
				<div
					className={`pagination-container ${
						props.showReplies !== false ? "" : "vanish"
					}`}>
					<Pagination
						count={props.numberOfFriendPages}
						page={Number(props.friendspage) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={2}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set(
								"friendspage",
								`${item.page}`
							);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();
							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											borderColor:
												"rgba(255, 255, 255, 0.8)",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		) : null;
	}

	if (props.type === "inreq") {
		return props.showReplies !== false ? (
			<ThemeProvider theme={paginationTheme}>
				<div
					className={`pagination-container ${
						props.showReplies !== false ? "" : "vanish"
					}`}>
					<Pagination
						count={props.numberOfInReqPages}
						page={Number(props.inreqpage) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={2}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set(
								"inreqpage",
								`${item.page}`
							);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();
							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											borderColor:
												"rgba(255, 255, 255, 0.8)",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		) : null;
	}

	if (props.type === "outreq") {
		return props.showReplies !== false ? (
			<ThemeProvider theme={paginationTheme}>
				<div
					className={`pagination-container ${
						props.showReplies !== false ? "" : "vanish"
					}`}>
					<Pagination
						count={props.numberOfOutReqPages}
						page={Number(props.outreqpage) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={2}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set(
								"outreqpage",
								`${item.page}`
							);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();
							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											borderColor:
												"rgba(255, 255, 255, 0.8)",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		) : null;
	}

	if (props.type === "blocklist") {
		return props.showReplies !== false ? (
			<ThemeProvider theme={paginationTheme}>
				<div
					className={`pagination-container ${
						props.showReplies !== false ? "" : "vanish"
					}`}>
					<Pagination
						count={props.numberOfBlockPages}
						page={Number(props.blockpage) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={2}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set(
								"blockpage",
								`${item.page}`
							);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();
							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											borderColor:
												"rgba(255, 255, 255, 0.8)",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		) : null;
	}

	if (["hints", "rates", "faves", "review"].includes(props.type)) {
		return (
			<ThemeProvider theme={paginationTheme}>
				<div className={`pagination-container}`}>
					<Pagination
						count={props.numberOfPages}
						page={
							Number(props?.hintpage) ||
							Number(props?.ratepage) ||
							Number(props?.favepage) ||
							Number(props?.reviewpage) ||
							1
						}
						variant="outlined"
						color="primary"
						className="pagination"
						boundaryCount={1}
						siblingCount={2}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							props.type === "hints" &&
								currentUrlSearchParams.set(
									"hintpage",
									`${item.page}`
								);
							props.type === "rates" &&
								currentUrlSearchParams.set(
									"ratepage",
									`${item.page}`
								);
							props.type === "faves" &&
								currentUrlSearchParams.set(
									"favepage",
									`${item.page}`
								);
							props.type === "review" &&
								currentUrlSearchParams.set(
									"reviewpage",
									`${item.page}`
								);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();
							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											borderColor:
												"rgba(255, 255, 255, 0.8)",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19px",
										},
									}}
								/>
							);
						}}
					/>
				</div>
			</ThemeProvider>
		);
	}
	if (props.type === "infraction") {
		return (
			<ThemeProvider theme={paginationTheme}>
				<div className="pagination-container">
					<Pagination
						count={props.numberOfPages}
						page={Number(props.infractionpage) || 1}
						variant="outlined"
						color="primary"
						className="pagination"
						size={paginationSize}
						boundaryCount={1}
						siblingCount={props.siblingCount}
						renderItem={(item) => {
							// render page equal to params set
							const currentUrlSearchParams = new URLSearchParams(
								window.location.search
							);
							currentUrlSearchParams.set(
								"infractionpage",
								`${item.page}`
							);
							const newUrlSearchParams =
								currentUrlSearchParams.toString();

							return (
								<PaginationItem
									component={Link}
									to={`${window.location.pathname}?${newUrlSearchParams}`}
									{...item}
									sx={{
										"&.Mui-selected": {
											backgroundColor:
												"rgba(20, 30, 60, 0.6)",
											fontPalette: "white",
											borderColor: "white",
											borderStyle: "dashed",
											borderWidth: "2.3px",
											fontSize: "19.5px",
										},
									}}
								/>
							);
						}}
					/>
					{/* pagination submit number form */}
					{props.doQuery !== false ? (
						<form onSubmit={handlePageSubmit}>
							<div className="page-select-main">
								<input
									type="text"
									value={pageInput}
									onChange={handlePageInputChange}
									maxLength={5}
									placeholder="Page..."
									className="page-select-textarea"
								/>
								<button
									type="submit"
									className="btn-primary button-container">
									<div className=" notranslate material-icons-round page-select-button">
										arrow_circle_right
									</div>
								</button>
							</div>
						</form>
					) : null}
				</div>
			</ThemeProvider>
		);
	} else return null;
};

export default Paginate;
