// react imports
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

// constants
import { LocalStorageConstants } from "../../constants";

// page styles
import "./style.scss";

// components
import { register, login } from "../../api";

// types
import { FormData } from "./types";
import pushHistory from "../../functions/pushHistory";

/**
 * Registration/login form.
 *
 * @param {*} props
 * @returns
 */
const Auth = (props) => {
	// state info
	const [lockAction, setLockAction] = useState<boolean>(false);
	const [regStatus, setRegStatus] = useState<boolean>(false);
	const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
	const [parentalGuide, setParentalGuide] = useState<boolean>(false);
	const [formData, setFormData] = useState<FormData>({
		username: "",
		email: "",
		password: "",
		passwordConfirm: "",
		verifiedCaptcha: false, // Only checked for when registering, not logging in.
		accountType: "none",
	});

	// hook initializations
	const history = useHistory();

	useEffect(() => {
		document.title = `${
			regStatus ? "Register" : "Login"
		} - Level Share Square`;
	}, [regStatus]);

	// ---------------------- //
	// Handle form submission //
	// ---------------------- //
	const handleSubmit = (e) => {
		// prevent form from submitting as normal
		e.preventDefault();

		if (lockAction === true) return;
		setLockAction(true);
		// if this is a registration form, check all fields
		if (
			formData.username &&
			formData.email &&
			formData.password &&
			formData.passwordConfirm &&
			formData.verifiedCaptcha &&
			regStatus
		) {
			// account type can't be none
			if (formData.accountType === "none") {
				setLockAction(false);
				return toast.error(
					"Please select an account type before proceeding.",
				);
			}
			// must have agreed to terms
			if (!acceptedTerms) {
				setLockAction(false);
				return toast.error(
					"You must agree to the terms of service in order to register an account.",
				);
			}
			// make api call
			return register(formData)
				.then(() => {
					// redirect to verification page
					history.push("/verify");
					localStorage.setItem(
						LocalStorageConstants.Toasts.MESSAGE,
						"Welcome to a new fantasy!",
					);
					window.location.reload();
				})
				.catch((err) => {
					if (err?.response?.data?.parentalGuide)
						setParentalGuide(true);
				})
				.finally(() => setLockAction(false));
		}
		// otherwise only check the ones needed to login
		if (formData.email && formData.password && !regStatus)
			return login(formData)
				.then((response) => {
					// redirect to previous page if one exists
					const redirectUrl =
						new URLSearchParams(window.location.search).get(
							"url",
						) ?? "";
					if (
						["/auth", "/resetpass", "/pagenotfound"].includes(
							redirectUrl,
						)
					) {
						history.push("/");
					} else if (redirectUrl && redirectUrl.startsWith("/")) {
						history.push(redirectUrl);
					} else {
						history.push("/");
					}

					// set toast message in local storage, executed at bottom of page
					// for every toast message upon page load.
					localStorage.setItem(
						LocalStorageConstants.Toasts.MESSAGE,
						"Your journey continues!",
					);
					window.location.reload();
				})
				.finally(() => setLockAction(false));
		// else show error
		setLockAction(false);
		toast.error(
			"Please properly fill in all the required fields before submitting!",
		);
	};

	// handle changes in filling out form
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	// This fires when the ReCAPTCHA has successfully been ticked.
	async function handleCaptchaUpdate(value) {
		// Communicate to the backend to perform a second check
		await axios
			.get("/api/recaptcha/", {
				params: { token: value },
			})
			.then((response) => {
				// If True is returned, ReCAPTCHA validation has succeeded, and the registration form can be submitted
				if (response.data === true) {
					setFormData({ ...formData, verifiedCaptcha: true });
				}
			});
	}

	return (
		<>
			{/* large screens */}
			<div className="container mt-4 align-mid">
				{/* only show header if this is a registration page */}
				{regStatus && (
					<>
						<div className="alert alert-secondary">
							<span className="lead">
								Create an account to directly start saving your
								levels!
							</span>
						</div>
					</>
				)}

				<div className="card mx-auto">
					<div className="card-header">
						<img
							src={props?.images?.LSSLogoTransparent}
							width="70"
							height="60"
							alt="LSS Logo"
						/>
						<span
							className="align-text-middle"
							style={{
								fontSize: "36px",
								position: "relative",
								top: "9px",
							}}>
							{regStatus ? "Register" : "Login"}
						</span>
					</div>
					<div className="card-body">
						<form onSubmit={handleSubmit}>
							<div
								className={
									regStatus
										? "register-field hover-colour"
										: ""
								}>
								{/* only ask for username if user is registering */}
								{regStatus && (
									<>
										<h2>Main</h2>
										<hr />
										<div className="form-group">
											<label
												htmlFor="username"
												className="label-adjust">
												Username
											</label>
											<input
												type="text"
												className="form-control"
												id="username"
												name="username"
												maxLength={32}
												value={formData.username}
												onChange={handleChange}
											/>
										</div>
									</>
								)}
								<div className="form-group">
									<label
										htmlFor="email"
										className="label-adjust">
										Email
									</label>
									<input
										type="email"
										className="form-control"
										id="email"
										name="email"
										maxLength={100}
										value={formData.email}
										onChange={handleChange}
									/>
								</div>

								<div className="form-group">
									<label
										htmlFor="password"
										className="label-adjust">
										Password
									</label>
									<input
										type="password"
										className="form-control"
										id="password"
										name="password"
										maxLength={255}
										value={formData.password}
										onChange={handleChange}
									/>
									{regStatus && (
										<>
											<label
												htmlFor="passwordConfirm"
												className="label-adjust">
												Confirm Password
											</label>
											<input
												type="password"
												className="form-control"
												id="passwordConfirm"
												name="passwordConfirm"
												maxLength={255}
												value={formData.passwordConfirm}
												onChange={handleChange}
											/>
										</>
									)}
									{!regStatus && (
										<label>
											<a href="/forgotpass">
												Forgot password?
											</a>
										</label>
									)}
								</div>
							</div>
							{/* only ask for password confirmation & ReCAPTCHA if user is registering */}
							{regStatus && (
								<div className="form-group">
									<div
										className="register-field hover-colour"
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-start",
										}}>
										<h2>Account type</h2>
										<div className="register-account-type">
											<input
												className="form-check-input"
												type="radio"
												name="ageConfirmation"
												value="minor"
												checked={
													formData.accountType ===
													"minor"
												}
												onChange={() =>
													setFormData((prev) => ({
														...prev,
														accountType: "minor",
													}))
												}
											/>
											<span>
												I am 12 years or younger
											</span>
										</div>
										<div className="register-account-type">
											<input
												className="form-check-input"
												type="radio"
												name="ageConfirmation"
												value="regular"
												disabled={parentalGuide}
												checked={
													formData.accountType ===
													"regular"
												}
												onChange={() =>
													setFormData((prev) => ({
														...prev,
														accountType: "regular",
													}))
												}
											/>
											<span>I am 13 years or older</span>
										</div>
										<div className="register-account-type">
											<input
												className="form-check-input"
												type="radio"
												name="ageConfirmation"
												value="guardian"
												checked={
													formData.accountType ===
													"guardian"
												}
												onChange={() =>
													setFormData((prev) => ({
														...prev,
														accountType: "guardian",
													}))
												}
											/>
											<span>
												I am a legal guardian creating
												an account for a minor in my
												care.
											</span>
										</div>
									</div>
									<label>
										<input
											className="form-check-input"
											type="checkbox"
											checked={acceptedTerms}
											onChange={() =>
												setAcceptedTerms(
													(prev) => !prev,
												)
											}
										/>
										&nbsp;I have read and I agree to the{" "}
										<a
											href="/tos/"
											onClick={(e) =>
												pushHistory(history, e, true)
											}>
											Terms of Service
										</a>
									</label>
									<label>
										<ReCAPTCHA
											sitekey="6LezV9ElAAAAAEm93SBxKkO6-ZFiKaewaqfTWHUt"
											onChange={handleCaptchaUpdate}
										/>
									</label>
								</div>
							)}
							{parentalGuide && (
								<div className="yellow">
									Please get a parent or your legal guardian
									to fill out this page instead. We want to
									ensure your safety on Level Share Square
									since you're below the age of 13. Verifying
									your account will also have to be done
									manually by us.
								</div>
							)}
							<br />
							<button
								type="submit"
								disabled={lockAction}
								className="btn btn-primary">
								{regStatus ? "Register" : "Login"}
							</button>
							<br />
							<br />
							{regStatus ? (
								<span className="align-text-middle">
									Already have an account?{" "}
									<a
										href="#!"
										onClick={() => setRegStatus(false)}>
										Login
									</a>
								</span>
							) : (
								<span className="align-text-middle">
									Don't have an account yet?{" "}
									<a
										href="#!"
										onClick={() => setRegStatus(true)}>
										Register
									</a>
								</span>
							)}
						</form>
					</div>
				</div>
				<br />
			</div>
		</>
	);
};

export default Auth;
