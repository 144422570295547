import { api } from "./index";

//!-------------------------//
//! --- user api routes --- //
//!-------------------------//

// users
export const returnUserByToken = () => api.get(`/users/get_id_from_token`);
export const deleteUserSession = () => api.delete(`/users/logout`);
export const fetchAllUsers = () => api.get("/users");
export const getMembersPage = (membersData) =>
	api.get(
		`/users?page=${membersData.page}&searchQuery=${membersData.searchQuery}`,
	);
export const getUserSet = (usersData) => api.post("/users/set", usersData);
export const getUser = (id) => api.get(`/users/${id}`);
export const getUserRates = (id, page) =>
	api.get(`/users/${id}/rates?page=${page}&type=account`);
export const getUsersWithAccess = (authorIds) =>
	api.get(`/users/levelaccess/${authorIds}`);
export const getAboutPage = () => api.get("/users/about/get");

export const updateUserLevels = (id) => api.patch(`/users/${id}`);
export const getOnlineUsers = (allUsers) =>
	api.get(`/users/online/get?all=${allUsers}`); // get currently online users

// authentication
export const login = (formData) => api.post("/users/login", formData);
export const register = (formData) => api.post("/users/register", formData);

// infractions
export const getInfractions = (target, infractionData) =>
	api.get(
		`/users/infractions/${target}?page=${infractionData.infractionpage}`,
	);
export const markReadInfraction = (target) =>
	api.patch(`/users/infractions/view/${target}`);

// verify user
export const verifyUser = (formData) => api.post("/verify", formData);
export const resendVerificationMail = (formData?) =>
	api.post("/verify/resend_mail", formData);
export const fetchEmail = (formData) =>
	api.post("/verify/check_email", formData);
export const changeEmail = (formData) =>
	api.patch("/verify/change_email", formData);
export const downloadAgreement = (type) => api.get("/verify/download_agreement/" + type, { responseType: "blob" });
