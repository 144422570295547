import React, { useContext, useEffect } from "react";
import { UserContext } from "../../App";
import { toast } from "react-toastify";

/**
 * Contact page used on the site.
 *
 * @returns The site's contact page.
 */
const Contact = (props) => {
	const currentUserData = useContext(UserContext);
	useEffect(() => {
		document.title = "Contact Us - Level Share Square";
	}, []);

	return (
		<div className="container mt-4">
			<div className="col-12 col-lg-9 mx-auto">
				<div className="card">
					<div className="card-header">
						<img
							src={props.images.Exclamation}
							alt="Exclamation"
							width="48"
							height="48"
						/>
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "32px" }}>
							Contact & Support
						</span>
					</div>
					<div className="card-body">
						<p>
							<strong>Note:</strong> If you're looking for
							support, be sure to check out our FAQ first, as it
							may answer your questions!
						</p>
						<hr />
						<h2>Contact methods</h2>
						<p>
							If you have any questions, concerns, or anything
							else that you want us to know, feel free to let us
							know!
						</p>
						<p>
							The fastest way to get in contact with our staff is
							through our Discord, as we will see your message
							instantly.
						</p>
						<p>
							If you prefer to contact us via email, that is also
							possible of course. Our email address is:
							<br />
							<strong>levelsharesquare@gmail.com</strong>
						</p>

						<a
							className="btn btn-discord"
							onClick={(e) => {
								if (!currentUserData?.guarded) return;
								e.preventDefault();
								e.stopPropagation();
								toast.warn(
									"Consider using a different method, discord enforces all its users to be 13 years or older.",
								);
							}}
							href="https://discord.gg/GA48Y5TXwS">
							Contact on Discord
						</a>

						<a
							className="btn btn-primary"
							href="mailto://levelsharesquare@gmail.com"
							style={{ marginLeft: "10px" }}>
							Send us an email
						</a>
					</div>
				</div>
				<br />
			</div>
		</div>
	);
};

export default Contact;
