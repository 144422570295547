import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useHistory } from "react-router-dom";
import BBCode from "@bbob/react/es/Component";
import BBCodeParser from "../../functions/applyBBCode";
import {
	concludeEvent,
	createEventSubmission,
	getEvent,
	getEventSubmissions,
	getSubmissionContent,
	gradeEventSubmission,
	updateEventVideo,
	voteEventPoll,
} from "../../api";
import Linkify from "react-linkify";
import DefaultThumbnail from "../../assets/images/DefaultThumbnail.png";
import "./style.scss";

// constants
import {
	Button,
	Modal,
	OverlayTrigger,
	Spinner,
	Tooltip,
} from "react-bootstrap";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";
import { AllowedBBCodeTags, Colors, Time } from "../../constants";
import { UserContext } from "../../App";
import UsernameAvatarDisplay from "../Insertions/UsernameAvatarDisplay";
import moment from "moment";
import LinkRenderer from "../Insertions/LinkRenderer";
import EventPoll from "./EventPoll";
import { validateLevelCode } from "../../functions/levelValidation";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import StarEmpty from "../../assets/vectors/StarEmpty";
import StarHalf from "../../assets/vectors/StarHalf";
import StarFull from "../../assets/vectors/StarFull";
import MapStars from "../Insertions/MapStars";
import { LevelFileUpload } from "../Insertions/FileUpload";
import downloadFile from "../../functions/downloadFile";

const Event = (props) => {
	const currentUserData = useContext(UserContext);

	const eventId = props.match.params.id;
	const [event, setEvent] = useState<any>({});
	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);
	const [eventMessage, setEventMessage] = useState<string>("");
	const [isQuerying, setIsQuerying] = useState<boolean>(false);

	// modals
	const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
	const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
	const [entryModalShow, setEntryModalShow] = useState<string>("");
	const [warningModalShow, setWarningModalShow] = useState<string>("");
	const [videoModalShow, setVideoModalShow] = useState<boolean>(false);

	// event videos
	const [videoId, setVideoId] = useState<string>("");
	const [confirm, setConfirm] = useState<boolean>(false);

	const active = event?.active;
	const loaded = event?._id;
	const startDate = new Date(event?.startDate);
	const endDate = new Date(event?.endDate);
	const startDateFormatted = (tFormat) => moment(startDate).format(tFormat);
	const endDateFormatted = (tFormat) => moment(endDate).format(tFormat);

	const [submissions, setSubmissions] = useState<any[]>([]);
	const [mySubmissions, setMySubmissions] = useState<any[]>([]);
	const allSubmissions = submissions.concat(mySubmissions);

	const isJudge = event?.judges?.some(
		(judge) => judge?._id === currentUserData?._id,
	);
	const isJudgeOrHost =
		isJudge ||
		event?.hosts?.some((host) => host?._id === currentUserData?._id);

	// event status
	const oneHourAfterStart = new Date(startDate.getTime() + 3600 * 1000);
	const afterStartDate = oneHourAfterStart <= new Date();
	const eventStatusId = active
		? 1
		: !afterStartDate
			? 0
			: !loaded
				? -1
				: event?.hasWinners
					? 3
					: 2;

	const eventStatuses = ["Unstarted", "Active", "Results pending", "Ended"];
	const eventStatus = eventStatuses[eventStatusId] || "Loading...";
	const showStartTime = !active && startDate >= new Date();

	const participateButtonName =
		currentUserData?.rank < 5
			? "Events unlock at rank 5"
			: event?.submissionType === "poll"
				? "Vote on poll"
				: "Submit";
	const disableParticipation =
		currentUserData?.rank < 5 || !currentUserData?._id;

	useEffect(() => {
		if (isQuerying) return setHasDoneQuery(true);

		if (!hasDoneQuery) {
			setIsQuerying(true);
			getEvent(eventId)
				.then((response) => {
					const eventResponse = response?.data?.event;
					setEvent(eventResponse);
					setVideoId(eventResponse?.video);
					document.title = `${eventResponse?.name} | ${eventResponse?.type} - Level Share Square`;
				})
				.catch((error) => {
					setEventMessage(error?.response?.data?.eventMessage);
					if (error?.response?.status === 504)
						setEventMessage(
							"Couldn't connect to LSS servers, try refreshing the page.",
						);
				})
				.finally(() => setIsQuerying(false));
		}
	}, [eventId, hasDoneQuery, isQuerying]);

	// returns activity status view
	const renderActivityStatus = (classVal) => (
		<OverlayTrigger
			placement="bottom"
			overlay={
				<Tooltip id={`${classVal}-tip`}>
					<div>
						{event?.type}{" "}
						{active || !afterStartDate ? "runs" : "ran"} from
					</div>
					<div className={Colors.GREEN}>
						{startDateFormatted(Time.MMM_DD_YYYY_TIME)}
					</div>
					<div>until exactly</div>
					<div className={Colors.BLACK}>
						{endDateFormatted(Time.MMM_DD_YYYY_TIME)}
					</div>
				</Tooltip>
			}>
			<div
				className={`${classVal} event-buttons__activity-display 
	${Colors.Array.R_O_G[eventStatusId - 1]} unselectable`}
				style={{ width: "100%" }}>
				<h2>{eventStatus}</h2>
				{showStartTime ? (
					<span className="event-buttons__time">
						Starts on
						<br />
						<b>{startDateFormatted(Time.MMM_DD_TIME)}</b>
					</span>
				) : (
					<span className="event-buttons__time">
						End{active ? "s" : "ed"} on <br />
						<b>{endDateFormatted(Time.MMM_DD_TIME)}</b>
					</span>
				)}
			</div>
		</OverlayTrigger>
	);

	const submissionType: string = event?.submissionType;
	const modalProps = { event, setEvent, showSubmitModal, setShowSubmitModal };

	// pop-up modal for submitting/voting
	const submissionModal = () => {
		return (
			<Modal
				show={showSubmitModal}
				onHide={() => setShowSubmitModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				scrollable
				size="lg"
				centered>
				<span className="modal-fill">
					{submissionType === "poll" ? (
						<EventVote modalProps={modalProps} />
					) : submissionType === "miniLevel" ? (
						<MiniContestSubmit
							modalProps={modalProps}
							gameProperties={props?.gameProperties}
							setSubmissions={setSubmissions}
							setMySubmissions={setMySubmissions}
						/>
					) : null}
				</span>
			</Modal>
		);
	};

	// information modal on the event
	const infoModal = () => (
		<Modal
			show={showInfoModal}
			onHide={() => setShowInfoModal(false)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="popup-modal">
			<span className="modal-fill">
				<Modal.Body className="modal-bdy modal-filter">
					<h1 className="center-textoutput">
						Extra event information
					</h1>
					<hr />
					<EventInformation event={event} />
				</Modal.Body>
				<Modal.Footer className="modal-buttons modal-filter">
					<Button onClick={() => setShowInfoModal(false)}>
						Close
					</Button>
				</Modal.Footer>
			</span>
		</Modal>
	);

	const handleEventConclusion = () => {
		if (isQuerying) return;
		setIsQuerying(true);
		setWarningModalShow("");
		// api call
		concludeEvent(eventId)
			.then(() => {
				setSubmissions([]);
				setEvent((prev) => ({
					...prev,
					hasResults: true,
					concluded: true,
				}));
			})
			.finally(() => setIsQuerying(false));
	};
	// information modal on the event
	const warningModal = () => (
		<Modal
			show={warningModalShow !== ""}
			onHide={() => setWarningModalShow("")}
			aria-labelledby="contained-modal-title-vcenter"
			size="sm"
			centered
			className="popup-modal">
			<span className="modal-fill">
				<Modal.Body className="modal-bdy modal-filter">
					{warningModalShow ? (
						<h4 className="center-textoutput">
							Are you sure you want to {warningModalShow} this
							event? All rewards will be handed out and this
							cannot be undone.
						</h4>
					) : (
						<h3 className="center-textoutput">Closing...</h3>
					)}
				</Modal.Body>
				<Modal.Footer className="modal-buttons modal-filter">
					<Button
						className="btn-primary__danger"
						onClick={handleEventConclusion}>
						Conclude
					</Button>
					<Button onClick={() => setWarningModalShow("")}>
						Close
					</Button>
				</Modal.Footer>
			</span>
		</Modal>
	);

	// update the content of the entry
	useEffect(() => {
		// stop if it already has content or the modal is closed
		if (!entryModalShow || isQuerying) return;
		const entry = allSubmissions?.find(
			(submission) => submission?._id === entryModalShow,
		);
		// fetch for the current user
		if (entryModalShow === "current") {
			if (mySubmissions?.length > 0 || !event?.hasSubmitted) return;
			// fetch documents
			getEventSubmissions(event?._id, {
				author: currentUserData?._id,
			}).then((res) => {
				const submissions = res?.data?.submissions;
				setMySubmissions(submissions || []);
			});
			// end
			return;
		}
		if (
			entry?.content?.length > 0 ||
			entry?.content === null ||
			entry?.message?.length > 0
		)
			return;
		// fetch and update
		setIsQuerying(true);
		getSubmissionContent(entryModalShow)
			.then((res) => {
				const message = res?.data?.message;
				const content = res?.data?.content;
				const reviews = res?.data?.reviews;
				const updateSubmissions = (newSubmissions) =>
					newSubmissions.map((sub) => {
						// set the content field
						if (sub._id === entry?._id) {
							sub.content = content || null;
							if (reviews) sub.reviews = reviews;
							if (message) sub.message = message;
						}
						return sub;
					});
				setSubmissions((prev) => updateSubmissions(prev));
				setMySubmissions((prev) => updateSubmissions(prev));
			})
			.catch(() => {
				const updateSubmissions = (newSubmissions) =>
					newSubmissions.map((sub) => {
						if (sub._id === entry?._id)
							sub.message = "Error retrieving submission content";
						return sub;
					});
				setSubmissions((prev) => updateSubmissions(prev));
				setMySubmissions((prev) => updateSubmissions(prev));
			})
			.finally(() => setIsQuerying(false));
	}, [
		entryModalShow,
		submissions,
		mySubmissions?.length,
		allSubmissions,
		event?._id,
		currentUserData?._id,
		event?.hasSubmitted,
		isQuerying,
	]);

	// viewing submissions
	const entryModal = () => {
		const entry = allSubmissions?.find(
			(submission) => submission?._id === entryModalShow,
		);
		return (
			<Modal
				show={entryModalShow !== ""}
				onHide={() => setEntryModalShow("")}
				size="xl"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<span className="modal-fill">
					{entryModalShow === "current" ? (
						<Modal.Body className="modal-filter modal-bdy">
							{mySubmissions?.length > 0 ? (
								<MapEntrySubmissions
									submissions={mySubmissions}
									setSubmissions={setMySubmissions}
									setEntryModalShow={setEntryModalShow}
									disabled={true}
									isJudgeOrHost={isJudgeOrHost}
									isJudge={isJudge}
									gameProperties={props.gameProperties}
									resultsPublic={event?.hasResults}
								/>
							) : (
								<div className="d-flex justify-content-center">
									<Spinner
										animation="grow"
										variant="primary"
									/>
								</div>
							)}
						</Modal.Body>
					) : entryModalShow !== "" ? (
						<EntryDisplay
							entry={entry}
							setSubmissions={setSubmissions}
							isJudgeOrHost={isJudgeOrHost}
							isJudge={isJudge}
							resultsPublic={event?.hasResults}
							judges={event?.judges}
							hoverCardProps={props?.hoverCardProps}
							hasResults={event?.hasResults}
						/>
					) : (
						<h1 className="center-textoutput">Closing...</h1>
					)}
					<Modal.Footer className="modal-filter modal-buttons">
						{entryModalShow !== "current" &&
							event?.hasSubmitted && (
								<Button
									className="btn-primary"
									onClick={() =>
										setEntryModalShow("current")
									}>
									View your submissions
								</Button>
							)}
						<Button
							className="btn-primary"
							onClick={() => setEntryModalShow("")}>
							Close
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	// announce videos/streams for events
	const handleVideoUpdate = (e, mode) => {
		e.preventDefault();
		setVideoModalShow(false);
		toast.info("Updating level video...");
		// backend call
		updateEventVideo({
			id: event?._id,
			mode: mode,
			video: videoId,
		}).then((res) =>
			setEvent((prev) => ({
				...prev,
				video: res?.data?.video,
			})),
		);
	};

	// popup modal for editing video
	const videoUrlModal = () => (
		<Modal
			show={videoModalShow}
			onHide={() => setVideoModalShow(false)}
			aria-labelledby="contained-modal-title-vcenter"
			className="popup-modal"
			centered>
			<span className="modal-fill">
				{videoModalShow ? (
					<>
						<Modal.Body>
							<h3 style={{ textAlign: "center" }}>
								Set video URL
							</h3>
							<hr />
							<input
								type="text"
								style={{ width: "100%", margin: "0 auto" }}
								title="level video"
								className="form-control"
								value={videoId || ""}
								onChange={(e) => setVideoId(e.target.value)}
							/>
						</Modal.Body>
						<Modal.Footer className="mx-auto modal-buttons">
							<button
								className="btn btn-primary__special"
								onClick={(e) => {
									if (!confirm) {
										setConfirm(true);
										setTimeout(
											() => setConfirm(false),
											3000,
										);
										return toast.info(
											"Click again to confirm",
										);
									}
									handleVideoUpdate(e, "announce");
								}}>
								{!confirm ? "Announce" : "Confirm announcement"}
							</button>
							<button
								className="btn btn-primary"
								onClick={(e) => handleVideoUpdate(e, "update")}>
								Save
							</button>
							<button
								className="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									setVideoModalShow(false);
								}}>
								Close
							</button>
						</Modal.Footer>
					</>
				) : (
					<h3 style={{ textAlign: "center" }}>Closing...</h3>
				)}
			</span>
		</Modal>
	);

	// no events found
	if (!loaded)
		return (
			<div className="container mt-4">
				{isQuerying ? (
					<div
						className="d-flex justify-content-center"
						style={{ marginTop: "25vh", marginBottom: "25vh" }}>
						<Spinner animation="grow" variant="primary" />
					</div>
				) : (
					<div
						className="card card-body"
						style={{
							textAlign: "center",
							marginTop: "20vh",
							marginBottom: "20vh",
						}}>
						<h2 className="center-textoutput">
							{eventMessage ||
								"Something went wrong loading this event."}
						</h2>
					</div>
				)}
			</div>
		);

	// render events page
	return (
		<>
			<div className="container mt-4 d-flex event-page">
				{renderActivityStatus("d-lg-none")}
				<div className="event-main">
					<div className="document-header justify-content-center">
						<h1>{event?.name}</h1>
					</div>
					<div className="card no-top-radius card-body">
						{/* event thumbnail and hosts/judges*/}
						<JudgeHostThumbDisplay
							event={event}
							hoverCardProps={props?.hoverCardProps}
							gameProperties={props?.gameProperties}
						/>
						{event?.video &&
							(event?.videoAnnounced || isJudgeOrHost) && ( // video or stream associated with event
								<>
									<hr />
									<h1 className="center-textoutput">
										Watch the video associated with this
										event
									</h1>
									{isJudgeOrHost &&
										!event?.videoAnnounced && (
											<div className="yellow center-textoutput">
												Pssst, other users won't see
												this until a host announces the
												video!
											</div>
										)}
									<br />
									<iframe
										title="event video"
										style={{
											aspectRatio: "16/9",
											border: "1px solid white",
											borderRadius: "5px",
											margin: "0 auto",
											width: "60%",
										}}
										src={`https://www.youtube.com/embed/${event?.video}`}
										allowFullScreen
									/>
								</>
							)}
						<hr />
						{/* event info */}
						{event?.description && (
							<div style={{ whiteSpace: "pre-wrap" }}>
								<Linkify
									componentDecorator={(
										decoratedHref,
										decoratedText,
										key,
									) => (
										<LinkRenderer
											decoratedHref={decoratedHref}
											decoratedText={decoratedText}
											key={key}
											rank={"bypass"}
										/>
									)}>
									<BBCode
										plugins={[BBCodeParser()]}
										options={{
											onlyAllowTags:
												AllowedBBCodeTags.BBCODE_EXTENDED_TAGS,
										}}>
										{removeExcessiveBreaks(
											event?.description,
										)}
									</BBCode>
								</Linkify>
								<hr />
							</div>
						)}
						{event?.rewards?.length > 0 && (
							<EventRewards event={event} />
						)}
						{event?.submissionType === "poll" && (
							<EventPoll
								event={event}
								setShowSubmitModal={setShowSubmitModal}
							/>
						)}
						{event?.active && (
							<>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "10px",
										justifyContent: "center",
									}}>
									{event?.submissionType !== "poll" && (
										<button
											className="btn btn-primary btn-block"
											onClick={() =>
												setShowInfoModal(true)
											}>
											Submission info
										</button>
									)}
									<button
										className="btn btn-primary__special btn-block"
										disabled={disableParticipation}
										onClick={() =>
											setShowSubmitModal(true)
										}>
										{disableParticipation
											? currentUserData?.rank < 5
												? "Must be rank 5+ to participate"
												: "Please log in to participate"
											: participateButtonName}
									</button>
								</div>
								<hr />
							</>
						)}
					</div>
				</div>
				<div className="d-flex flex-column event-buttons">
					{renderActivityStatus("d-none d-lg-block")}
					<RenderButtons
						event={event}
						currentUserData={currentUserData}
						participateButtonName={participateButtonName}
						setShowSubmitModal={setShowSubmitModal}
						setInfoModal={setShowInfoModal}
						setEntryModalShow={setEntryModalShow}
						disableParticipation={disableParticipation}
						setWarningModalShow={setWarningModalShow}
						setVideoModalShow={setVideoModalShow}
					/>
				</div>
			</div>
			<div className="container mt-4">
				<MapEntrySubmissions
					event={event}
					submissions={submissions}
					setSubmissions={setSubmissions}
					setEntryModalShow={setEntryModalShow}
					isJudgeOrHost={isJudgeOrHost}
					gameProperties={props.gameProperties}
					isJudge={isJudge}
					resultsPublic={event?.hasResults}
				/>
			</div>
			{submissionModal()}
			{infoModal()}
			{entryModal()}
			{warningModal()}
			{videoUrlModal()}
		</>
	);
};

const RenderButtons = (props) => {
	const event = props?.event;
	const currentUserData = props?.currentUserData;
	const active = event?.active;
	const isJudge = event?.judges?.some(
		(judge) => judge?._id === currentUserData?._id,
	);
	const isHost = event?.hosts?.some(
		(host) => host?._id === currentUserData?._id,
	);
	const noPoll = event?.submissionType !== "poll";

	return (
		<>
			{noPoll && (
				<button
					className="btn btn-primary btn-block"
					onClick={() => props?.setInfoModal(true)}>
					Submission info
				</button>
			)}
			{active && !isHost && !isJudge && noPoll && (
				<>
					<button
						className="btn btn-primary__special btn-block"
						disabled={props?.disableParticipation}
						onClick={() => props?.setShowSubmitModal(true)}>
						{props?.disableParticipation
							? currentUserData?.rank < 5
								? "Must be rank 5+ to participate"
								: "Please log in to participate"
							: props?.participateButtonName}
					</button>
					{noPoll && currentUserData?._id && (
						<button
							disabled={!event?.hasSubmitted}
							className="btn btn-primary btn-block"
							onClick={() => props?.setEntryModalShow("current")}>
							My submissions
						</button>
					)}
				</>
			)}
			{isHost && (
				<>
					<button
						className="btn btn-primary btn-block"
						disabled={true}>
						Edit
					</button>
					<button
						className="btn btn-primary__danger btn-block"
						onClick={() => props?.setWarningModalShow("conclude")}
						disabled={
							event?.active === true ||
							event?.endDate < Date.now() ||
							event?.hasResults
						}>
						Conclude
					</button>
					<button
						className="btn btn-primary btn-block"
						onClick={() => props?.setVideoModalShow(true)}>
						Attach video/stream
					</button>
				</>
			)}
		</>
	);
};

const JudgeHostThumbDisplay = (props) => {
	// hosts and judges
	const event = props?.event;
	const hosts = event?.hosts || [];
	const judges = event?.judges || [];
	const hostHeader = hosts?.length === 1 ? "Host" : "Hosts";
	const judgeHeader = judges?.length === 1 ? "Judge" : "Judges";
	const allowedGames = event?.gameRequirement;
	const parsedAllowedGames = props?.gameProperties?.filter((game) =>
		allowedGames?.includes(game?.internalID?.toString()),
	);

	return (
		<div className="d-flex flex-row judge-host-flex">
			<div
				style={{
					backgroundImage: `url(${
						event?.thumbnail || DefaultThumbnail
					}`,
					position: "relative",
				}}
				className="event-img">
				{event?.submissionType === "poll" ? (
					<></>
				) : parsedAllowedGames?.length ? (
					parsedAllowedGames?.map((game, index) => (
						<img
							src={game?.cleanLogo}
							className="unselectable"
							alt={`Game Logo for ${game?.name}`}
							style={{
								height: "40px",
								position: "absolute",
								bottom: "0px",
								right: `calc(0px + ${index * 40}px)`,
								zIndex: "99",
							}}
						/>
					))
				) : (
					<div className="unselectable all-indicator">ALL</div>
				)}
			</div>
			<div style={{ width: "100%" }}>
				<h2>{hostHeader}</h2>
				<div className="flex-user-display">
					{hosts?.map((host) => (
						<React.Fragment key={host?._id}>
							<UsernameAvatarDisplay
								user={host}
								{...props?.hoverCardProps}
							/>
						</React.Fragment>
					))}
				</div>
				<hr />
				<h2>{judgeHeader}</h2>
				<div className="flex-user-display">
					{judges?.length ? (
						judges?.map((judge) => (
							<React.Fragment key={judge?._id}>
								<UsernameAvatarDisplay
									user={judge}
									{...props?.hoverCardProps}
								/>
							</React.Fragment>
						))
					) : (
						<i className={Colors.GRAY}>This event has no judges.</i>
					)}
				</div>
			</div>
		</div>
	);
};

const EventRewards = (props) => {
	const event = props?.event;
	const eventRewards: any[] = event?.rewards;

	const getPlacementName = (input: number) => {
		let outputString: string = "";

		if (!input) return "";
		// get the correct suffix
		switch (input) {
			case 1:
				outputString = "st";
				break;
			case 2:
				outputString = "nd";
				break;
			case 3:
				outputString = "rd";
				break;
			default:
				outputString = "th";
				break;
		}
		// return the string
		return `${input}${outputString}`;
	};

	return (
		<div>
			<h1 className="yellow">Rewards</h1>
			{eventRewards?.map((reward, index) => {
				// map all the rewards
				const placement: string = getPlacementName(reward?.placement);
				const placementRange: string = getPlacementName(
					reward?.placementRange,
				);

				return (
					<React.Fragment key={index}>
						{/* Display to which placement the reward goes at the top*/}
						<b>
							{placement}
							{placementRange !== "" &&
								` to ${placementRange}`}{" "}
							place
						</b>
						<ul>
							{Object.keys(reward)
								// remove placement info before mapping
								.filter(
									(key) =>
										![
											"placement",
											"placementRange",
										].includes(key),
								)
								?.map((key) => {
									// then map all the contents of the reward
									const value: string = reward?.[key];

									// items
									if (Array.isArray(value))
										return value.map((val) => (
											<li
												key={
													index +
													"-" +
													key +
													"-" +
													val?.value
												}>
												{val?.value}{" "}
												<i className="gray">
													({val?.type})
												</i>
											</li>
										));

									// singular rewards
									return (
										<li key={index + "-" + key}>
											{value} {key}
										</li>
									);
								})}
						</ul>
					</React.Fragment>
				);
			})}
			<hr />
		</div>
	);
};

const MapEntrySubmissions = (props) => {
	const event = props?.event;
	const loadRef = useRef(null);
	const currentUserData = useContext(UserContext);
	const isJudge = props?.isJudge;
	const isJudgeOrHost = props?.isJudgeOrHost;
	const authorized = isJudgeOrHost || currentUserData?.isAdmin === true;
	const hasEntries = event?.submissionCount > 0;
	const [page, setPage] = useState<number>(1);
	const [loadEntries, setLoadEntries] = useState<boolean>(
		(authorized || event?.publicSubmissions === true) &&
			hasEntries &&
			event?.visibility !== "misc",
	);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const cardBorder = [
		"rgba(255, 208, 0, 0.5)",
		"rgba(195, 195, 195, 0.5)",
		"rgba(188, 94, 0, 0.6)",
	];

	const history = useHistory();

	const setSubmissions = props.setSubmissions;

	// conclusion
	useEffect(() => {
		if (event?.concluded && page !== 1 && !props?.submissions?.length) {
			setPage(1);
			setLoadEntries(true);
		}
	}, [event?.concluded, props?.submissions?.length, page]);

	useEffect(() => {
		// don't start if loading entries is false
		if (props?.disabled) return;
		if (!loadEntries) return;
		const currentLoadRef = loadRef?.current;
		// Fetch data function
		const fetchSubmissions = () => {
			setIsFetching(true);
			setLoadEntries(false);
			getEventSubmissions(event?._id, { page: page })
				.then((res) => {
					const newSubmissions = res?.data?.submissions || [];
					if (newSubmissions?.length) {
						setSubmissions((prev) => [...prev, ...newSubmissions]);
						setPage((prev) => prev + 1);
						if (res?.data?.pagesLeft > 0) setLoadEntries(true);
					}
				})
				.finally(() => setIsFetching(false));
		};

		// IntersectionObserver callback
		const observerCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			if (entry.isIntersecting) {
				fetchSubmissions();
			}
		};

		// Set up IntersectionObserver
		const observer = new IntersectionObserver(observerCallback, {
			root: null, // Observes viewport
			rootMargin: "0% 0% -10% 0%", // fetch margin
			threshold: 1.0, // element msut be fully visisble
		});

		if (currentLoadRef) {
			observer.observe(currentLoadRef);
		}

		// Clean up
		return () => {
			if (currentLoadRef) {
				observer.unobserve(currentLoadRef);
			}
		};
	}, [page, event?._id, loadEntries, setSubmissions, props?.disabled]);

	if (
		(!hasEntries ||
			(!isJudgeOrHost && !event?.publicSubmissions && event?.active)) &&
		!props?.disabled
	)
		return null;

	return (
		<>
			<h1
				className="center-textoutput"
				style={{ margin: props?.disabled ? "0" : "12px" }}>
				{props?.disabled ? (
					<>
						Your submissions
						<hr />
					</>
				) : (
					"Submissions"
				)}
			</h1>
			<div className="entry-grid">
				{props?.submissions?.map((sub) => {
					const totalRating = sub?.totalGrade;
					const hasGraded = sub?.hasGraded;
					const hasReviewed = sub?.yourReview?.length > 0;
					const dq = sub?.disqualified;
					const topThree = 3 >= sub?.placement && event?.hasResults;

					return (
						<React.Fragment key={sub?._id}>
							<div
								id={sub?._id}
								className="card card-body hover-colour"
								style={
									dq
										? {
												border: "2px solid rgba(199, 7, 0, 0.3)",
												backgroundColor:
													"rgba(255, 81, 0, 0.1)",
											}
										: topThree
											? {
													border:
														"3px solid " +
														cardBorder[
															sub?.placement - 1
														],
													backgroundColor:
														"rgba(68, 255, 0, 0.08)",
												}
											: {
													border: "2px solid rgba(255, 255, 255, 0.2)",
												}
								}>
								<h3 className="center-textoutput">
									{sub?.name}
								</h3>
								{totalRating || hasGraded || dq ? (
									<div
										title={
											isJudge && !props?.resultsPublic
												? "Your rating will be published at the end of the contest"
												: "Total rating"
										}>
										{dq ? (
											<h2 className="center-textoutput hydrawisp-red">
												DISQUALIFIED
											</h2>
										) : (
											<MapStars
												level={null}
												rate={
													totalRating ||
													sub?.yourGrade
												}
												starCount={5}
												top="0"
												color={
													isJudge &&
													!props?.resultsPublic
														? "#c70700"
														: topThree
															? "#2cd40f"
															: ""
												}
												width={"11mm"}
												height={"11mm"}
											/>
										)}
										{totalRating && event?.hasResults && (
											<div
												className="center-textoutput"
												style={{ marginTop: "4px" }}>
												Average:{" "}
												<span className="yellow">
													{Math.round(
														totalRating * 100,
													) / 100}
													&#x2605;
												</span>
											</div>
										)}
									</div>
								) : (
									<i className="gray center-textoutput">
										{props?.isJudgeOrHost
											? "This entry has no rating yet."
											: "Ratings not available yet."}
									</i>
								)}
								<hr />
								{isJudge && (
									<div
										className={
											hasGraded
												? "wildheart-green"
												: "yellow"
										}>
										{hasGraded
											? `You graded ${hasReviewed ? "and reviewed " : ""} this entry`
											: "Not graded yet by you"}
									</div>
								)}
								<div>
									By{" "}
									<a
										href={`/users/${sub?.author?._id}`}
										onClick={(e) => {
											if (e.ctrlKey) return;
											e.preventDefault();
											history.push(
												`/users/${sub?.author?._id}`,
											);
											window.scrollTo(0, 0);
										}}>
										{sub?.author?.username}
									</a>
								</div>
								<div>
									Posted:{" "}
									<b>
										{moment(sub?.postDate).format(
											`MMM D, YYYY - h:mm A`,
										)}
									</b>
								</div>
								{sub?.game >= 0 && (
									<div>
										Game:{" "}
										<b>
											<span
												className={`game__${sub?.game}`}>
												{
													props?.gameProperties?.find(
														(game) =>
															game?.internalID ===
															sub?.game,
													)?.name
												}
											</span>
										</b>
									</div>
								)}
								<hr />
								<button
									className={`btn btn-primary${topThree ? "__special" : ""}`}
									onClick={() =>
										props.setEntryModalShow(sub?._id)
									}>
									View
								</button>
							</div>
						</React.Fragment>
					);
				})}
			</div>
			<div ref={loadRef}>
				{isFetching ? (
					<div
						className="d-flex justify-content-center"
						style={{ marginTop: "12px" }}>
						<Spinner animation="grow" variant="primary" />
					</div>
				) : null}
			</div>
		</>
	);
};

const EntryDisplay = (props) => {
	const currentUserData = useContext<any>(UserContext);
	const entry = props?.entry;
	const [reviewState, setReviewState] = useState<boolean>(false);
	const [overviewState, setOverviewState] = useState<boolean>(false);
	const [review, setReview] = useState<string>(entry?.yourReview || "");
	const [rating, setRating] = useState<number>(entry?.yourGrade || 0);
	const [isPatching, setIsPatching] = useState<boolean>(false);
	const [editReview, setEditReview] = useState(true);

	const totalRating = entry?.totalGrade;
	const topThree = 3 >= entry?.placement;

	const handleReviewEntry = (e) => {
		e.preventDefault();

		// validate rating
		if (rating < 0.5 || rating > 5)
			return toast.error("Valid ratings range between 0.5 and 5 stars.");

		if (isPatching) return;

		// api call
		setIsPatching(true);
		gradeEventSubmission(entry?._id, {
			review,
			rating,
		})
			.then((res) => {
				setReviewState(false);
				props.setSubmissions((prev) =>
					prev.map((sub) =>
						sub?._id === entry?._id
							? {
									...sub,
									reviews: res?.data?.reviews,
									hasGraded: true,
									yourGrade: rating,
									yourReview: review,
								}
							: sub,
					),
				);
				localStorage.removeItem(`draft-${entry?._id}`);
			})
			.finally(() => setIsPatching(false));
	};

	const handleDownload = (entry) => {
		// Check if entry.content is available and has the binary data
		if (entry?.content) {
			try {
				// Assuming level.fileData contains the binary data received from the server
				const binaryData: string = atob(entry.content); // atob decodes a base64-encoded string
				const byteArray: Uint8Array = new Uint8Array(binaryData.length);

				// Convert binary string into Uint8Array
				for (let i = 0; i < binaryData.length; i++) {
					byteArray[i] = binaryData.charCodeAt(i);
				}

				// Create a Blob from the binary data
				const blob: Blob = new Blob([byteArray], {
					type: "application/zip",
				});
				return downloadFile(blob, entry);
			} catch (error) {
				toast.error(
					"Error downloading file, check the console for details.",
				);
				return console.error("Download error:", error);
			}
		}
		toast.error("This level doesn't have a file to download.");
	};

	// Call the handleDownload function when needed

	useEffect(() => {
		// make sure there is no existing review
		if (entry?.yourReview?.length) return;

		// draft must exist to load from
		const draft = localStorage.getItem(`draft-${entry?._id}`);
		if (!draft) return;

		// load the draft
		setReview(JSON.parse(draft));
	}, [entry?.yourReview?.length, currentUserData?._id, entry?._id]);

	useEffect(() => {
		// store the review upon page unload
		const storeReview = () => {
			if (entry?.yourReview?.length || isPatching) return;
			localStorage.setItem(`draft-${entry?._id}`, JSON.stringify(review));
		};
		window.addEventListener("beforeunload", storeReview);
		// also upon component unmount
		return () => {
			window.removeEventListener("beforeunload", storeReview);
			storeReview();
		};
	}, [review, entry?._id, entry?.yourReview?.length, isPatching]);

	return (
		<Modal.Body className="modal-bdy modal-filter">
			{entry?.content ? (
				<>
					<h1 className="center-textoutput">
						{reviewState && "Grade "}
						{entry?.name}
						{overviewState && " - rates & reviews"}
					</h1>
					<hr />
					{!reviewState && !overviewState ? (
						<>
							{totalRating ||
							entry?.hasGraded ||
							entry?.disqualified ? (
								<div
									title={
										props?.isJudge && !props?.resultsPublic
											? "Your rating will be published at the end of the contest"
											: "Total rating"
									}>
									{entry?.disqualified ? (
										<h2 className="center-textoutput hydrawisp-red">
											DISQUALIFIED
										</h2>
									) : (
										<MapStars
											level={null}
											rate={
												totalRating || entry?.yourGrade
											}
											starCount={5}
											top="-8px"
											color={
												props?.isJudge &&
												!props?.resultsPublic
													? "#c70700"
													: topThree
														? "#2cd40f"
														: ""
											}
											width={"13.5mm"}
											height={"13.5mm"}
										/>
									)}
								</div>
							) : (
								<h6 className="center-textoutput">
									<i className="gray">
										{props?.isJudgeOrHost
											? "This entry has no rating yet."
											: "Ratings not available yet."}
									</i>
								</h6>
							)}
							{entry?.binaryContent ? (
								<div
									style={{
										minWidth: "50%",
										maxWidth: "max-content",
										margin: "0 auto",
									}}>
									<button
										className="btn-download"
										onClick={() => {
											handleDownload(entry);
										}}>
										<span>Download</span>
										<span
											className=" notranslate material-icons"
											style={{
												fontSize: "calc(14px + 4vw)",
											}}>
											download
										</span>
									</button>
								</div>
							) : (
								<textarea
									readOnly={true}
									value={entry?.content}
									title="Entry Content"
									style={{
										resize: "none",
										height: "40vh",
									}}
								/>
							)}
							<div
								className="modal-buttons"
								style={{ gap: "6px" }}>
								{props?.isJudge && !props?.hasResults && (
									<Button
										className="btn-primary__special"
										onClick={() =>
											setReviewState((prev) => !prev)
										}>
										Rate/Review
									</Button>
								)}
								{!entry?.binaryContent && (
									<Button
										className="btn-primary"
										onClick={() =>
											navigator.clipboard
												.writeText(entry?.content)
												.then(() =>
													toast.success(
														"Copied to clipboard.",
													),
												)
										}>
										Copy to clipboard
									</Button>
								)}
								{((!props?.isJudge && props?.isJudgeOrHost) ||
									props?.resultsPublic) && (
									<Button
										className="btn-primary__special"
										disabled={!entry?.reviews?.length}
										onClick={() =>
											setOverviewState((prev) => !prev)
										}>
										Grades
									</Button>
								)}
							</div>
						</>
					) : reviewState ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "12px",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<ReactStars
								classNames="unselectable"
								count={5}
								isHalf={true}
								value={rating}
								onChange={(newRating) => {
									setRating(newRating);
									toast.warn(
										`Your rating of ${newRating} stars will be saved once you click "Submit".`,
									);
								}}
								size={8}
								edit={true}
								emptyIcon={
									<StarEmpty width="13mm" height="13mm" />
								}
								halfIcon={
									<StarHalf
										width="13mm"
										height="13mm"
										color="#c70700"
									/>
								}
								filledIcon={
									<StarFull
										width="13mm"
										height="13mm"
										color="#c70700"
									/>
								}
							/>
							<div style={{ width: "100%" }}>
								{editReview ? (
									<textarea
										className="form-control"
										maxLength={20000}
										style={{ resize: "none" }}
										value={review}
										onChange={(e) =>
											setReview(e.target.value)
										}
									/>
								) : (
									<div
										className="review-content"
										style={{
											padding: "8px",
											backgroundColor:
												"rgba(0, 0, 0, 0.28)",
											boxShadow:
												"0 0 3px 3px rgba(0, 0, 0, 0.28)",
											margin: "0 auto",
											width: "80%",
										}}>
										<BBCode
											plugins={[BBCodeParser()]}
											options={{
												onlyAllowTags: [
													...AllowedBBCodeTags.BBCODE_EXTENDED_TAGS,
													...AllowedBBCodeTags.BBCODE_BONUS_TAGS,
												],
											}}>
											{review}
										</BBCode>
									</div>
								)}
							</div>
							<div
								className="modal-buttons"
								style={{ gap: "6px" }}>
								<Button onClick={() => setReviewState(false)}>
									Back
								</Button>
								<Button
									onClick={() =>
										setEditReview((prev) => !prev)
									}>
									{!editReview ? "Edit" : "Preview"}
								</Button>
								<Button
									className="btn-primary__special"
									disabled={isPatching}
									onClick={handleReviewEntry}>
									Submit
								</Button>
							</div>
						</div>
					) : overviewState ? (
						<div>
							<div className="modal-buttons">
								<Button onClick={() => setOverviewState(false)}>
									Back to entry
								</Button>
							</div>
							<hr />
							{entry?.reviews?.map((review, key) => {
								// get the judge
								const judge = props?.judges?.find(
									(j) => j?._id === review?.author,
								);

								return (
									<React.Fragment
										key={review?.author + "-" + key}>
										<GradeDisplay
											judge={judge}
											review={review}
											hoverCardProps={
												props?.hoverCardProps
											}
										/>
									</React.Fragment>
								);
							})}
						</div>
					) : null}
				</>
			) : entry?.message ? (
				<h6 className="center-textoutput">
					<i className=" gray">{entry?.message}</i>
				</h6>
			) : (
				<div className="d-flex justify-content-center">
					<Spinner animation="grow" variant="primary" />
				</div>
			)}
		</Modal.Body>
	);
};

const GradeDisplay = ({ judge, review, hoverCardProps }) => {
	const [showContent, setShowContent] = useState(false);
	const [hideReview, setHideReview] = useState(true);
	const [defaultHeight, setDefaultHeight] = useState<number>(0);
	const [elementHeight, setElementHeight] = useState<string | number>("auto");
	const [renderState, setRenderState] = useState(0);
	const defaultRef = useRef<any>(null);
	const reviewRef = useRef<any>(null);
	const noContent = !review?.content;

	useEffect(() => {
		const current = defaultRef?.current;
		if (current === null) return setRenderState((prev) => prev + 1);

		const height = current?.clientHeight;
		const reviewHeight = reviewRef?.current?.clientHeight;
		// update default height only once
		if (defaultHeight === 0) return setDefaultHeight(height);
		// update entire container
		setElementHeight(
			showContent ? defaultHeight + reviewHeight + 24 : defaultHeight,
		);
	}, [renderState, showContent, defaultHeight]);

	return (
		<div
			className={`grade-container ${showContent ? "expanded" : ""}`}
			onTransitionEnd={() => setHideReview(!showContent)}
			ref={defaultRef}
			style={{
				height: defaultHeight === 0 ? "auto" : elementHeight,
			}}>
			<div className="grade-display">
				<MapStars
					level={null}
					rate={review?.rating}
					starCount={5}
					top="-1px"
					width={"11mm"}
					height={"11mm"}
					color=""
				/>
				<UsernameAvatarDisplay user={judge} {...hoverCardProps} />
				<OverlayTrigger
					placement="top"
					overlay={
						noContent ? (
							<Tooltip id="no-review">
								This judge left no review
							</Tooltip>
						) : (
							<Tooltip id="toggle-review">
								{showContent ? "Hide" : "Show"} review
							</Tooltip>
						)
					}>
					<button
						className={`action-button${showContent ? "-enabled" : ""}`}
						disabled={noContent}
						onClick={() => setShowContent((prev) => !prev)}>
						<span className=" notranslate material-symbols-outlined">
							reviews
						</span>
					</button>
				</OverlayTrigger>
			</div>
			<div
				ref={reviewRef}
				className="review-content"
				style={{
					display: !showContent && hideReview ? "none" : "block",
					opacity: showContent ? 1 : 0,
				}}>
				<BBCode
					plugins={[BBCodeParser()]}
					options={{
						onlyAllowTags: [
							...AllowedBBCodeTags.BBCODE_EXTENDED_TAGS,
							...AllowedBBCodeTags.BBCODE_BONUS_TAGS,
						],
					}}>
					{review?.content}
				</BBCode>
			</div>
		</div>
	);
};

const EventVote = (props) => {
	const modalProps = props?.modalProps;
	const event = modalProps?.event;
	const currentUserData = useContext(UserContext);
	const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
	const [initialOptions, setInitialOptions] = useState<number[]>([]);
	const [disabled, setDisabled] = useState<boolean>(false);
	const unchanged =
		JSON.stringify(initialOptions) !== JSON.stringify(selectedOptions);
	const voteMessage =
		selectedOptions?.length > 0
			? unchanged
				? "Cast your vote!"
				: "Change your vote first."
			: initialOptions?.length > 0
				? "Remove your vote."
				: "Please select an option.";

	// vote on poll
	const handleVote = () => {
		setDisabled(true);
		modalProps.setShowSubmitModal(false);
		voteEventPoll(event?._id, selectedOptions).then((res) => {
			modalProps.setEvent((prev) => {
				return { ...prev, pollOptions: res?.data?.options };
			});
		});
	};

	// upon render, get the user's initial votes
	const getInitialVotes = useCallback(() => {
		const defaultVotes: number[] = [];
		for (let i = 0; i < event?.pollOptions?.length; i++) {
			const votes = event?.pollOptions?.[i]?.votes;
			// find index of user id
			const userIndex = votes?.indexOf(currentUserData?._id);
			if (userIndex !== -1) {
				// add option to default votes
				defaultVotes.push(i);
			}
		}
		return defaultVotes;
	}, [currentUserData?._id, event?.pollOptions]);

	useEffect(() => {
		const votes = getInitialVotes();
		setSelectedOptions(votes);
		setInitialOptions(votes);
	}, [getInitialVotes]);

	const handleSelect = (index: number, hasSelected: boolean) => {
		if (!hasSelected) {
			// if there is multiple choice
			if (event?.pollMultiChoice)
				return setSelectedOptions((prev) => [...prev, index]);
			// if only one can be selected at a time
			return setSelectedOptions([index]);
		}
		// if the option is being unselected
		return setSelectedOptions(selectedOptions.filter((i) => i !== index));
	};

	return (
		<>
			<Modal.Body className="modal-filter modal-bdy">
				<h1 className="center-textoutput">{event?.pollTitle}</h1>
				<hr />
				<div className="event-vote">
					{event?.pollOptions?.map((option, key) => {
						const hasSelected = selectedOptions?.includes(key);

						return (
							<div key={key}>
								<div
									style={
										hasSelected
											? {
													border: "3px solid rgb(240, 208, 0)",
													backgroundColor:
														"rgba(240, 208, 0, 0.2)",
												}
											: {
													border: "3px solid rgb(255, 255, 255)",
												}
									}
									className={`event-vote__option hover-colour clickable unselectable`}
									onClick={() =>
										handleSelect(key, hasSelected)
									}>
									{option?.image && (
										<img
											src={option?.image}
											alt={option?.value}
											style={{
												width: "25%",
												aspectRatio: "1",
												objectFit: "contain",
												objectPosition: "center",
												borderRadius: "10px",
												border: hasSelected
													? "3px solid rgb(240, 208, 0)"
													: "3px solid white",
												float: "left",
												marginRight: "20px",
												backgroundColor:
													"rgb(185, 185, 185)",
											}}
										/>
									)}
									<div style={{ whiteSpace: "pre-wrap" }}>
										<h2>
											<b>{option?.value}</b>
										</h2>
										<Linkify
											componentDecorator={(
												decoratedHref,
												decoratedText,
												key,
											) => (
												<LinkRenderer
													decoratedHref={
														decoratedHref
													}
													decoratedText={
														decoratedText
													}
													key={key}
													rank={"bypass"}
												/>
											)}>
											<BBCode
												plugins={[BBCodeParser()]}
												options={{
													onlyAllowTags: [
														...AllowedBBCodeTags.BBCODE_EXTENDED_TAGS,
														...AllowedBBCodeTags.BBCODE_BONUS_TAGS,
													],
												}}>
												{option?.description}
											</BBCode>
										</Linkify>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</Modal.Body>
			<Modal.Footer className="modal-buttons modal-filter">
				<Button
					onClick={() => handleVote()}
					disabled={!unchanged || disabled}>
					{modalProps?.showSubmitModal ? voteMessage : "closing...."}
				</Button>
				<Button
					disabled={disabled}
					onClick={() => modalProps.setShowSubmitModal(false)}>
					Close
				</Button>
			</Modal.Footer>
		</>
	);
};

const MiniContestSubmit = (props) => {
	const modalProps = props?.modalProps;
	const currentUserData = useContext<any>(UserContext);
	const event = modalProps?.event;
	const gameProps = props?.gameProperties;

	type LevelSubmission = {
		_id?: any;
		name?: string;
		author?: any;
		content?: any;
		binaryContent?: boolean;
		game?: number | null;
	};
	const [submission, setSubmission] = useState<LevelSubmission>({
		content: null,
	});
	const [validCode, setValidCode] = useState<boolean | null>(null);
	const [binaryContent, setBinaryContent] = useState<boolean>(false);
	const game = gameProps?.find(
		(game) => game?.internalID === submission?.game,
	);
	const gameId = game?.internalID;
	// a valid level has a code, a name, and a game
	const validLevel: boolean =
		validCode === true &&
		submission?.content &&
		gameId >= 0 &&
		gameId <= gameProps?.length - 1;

	const changeUploadType = (mode) => {
		setBinaryContent(mode);
		setValidCode(null);
		setSubmission((prev) => ({
			...(prev ? prev : {}),
			game: null,
			content: null,
			name: "",
		}));
	};

	const handleCodeChange = (value: string) => {
		// get the level code properties (eg. game and name)
		const result: any = validateLevelCode(
			value,
			submission?.game,
			null,
			null,
		);
		// upon invalid result
		if (!result?.isValid) {
			setSubmission((prev) => ({
				...(prev ? prev : {}),
				content: value,
			}));
			return setValidCode(false);
		}
		// upon valid result
		setValidCode(true);
		setSubmission((prev) => ({
			...(prev ? prev : {}),
			content: value,
			binaryContent: false,
			game: result?.game,
			name: submission?.name ? submission?.name : result?.name,
			author: submission?.author
				? submission?.author
				: currentUserData?._id,
		}));
	};

	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleSubmit = () => {
		if (isSubmitting) return;
		setIsSubmitting(true);
		modalProps.setShowSubmitModal(false);
		// create a formdata object to send to the server, then delete it on the original object
		const formData = new FormData();
		formData.append(binaryContent ? "file" : "code", submission?.content);
		delete submission?.content;
		formData.append("submission", JSON.stringify(submission));

		toast.info("Submitting entry...");
		createEventSubmission(formData, event?._id).then((res) => {
			localStorage.removeItem("levelSubmission");
			const entry = res?.data?.submission;
			if (entry) {
				// first entry
				if (!event?.hasSubmitted) {
					props.setMySubmissions([entry]);
					modalProps.setEvent((prev) => ({
						...prev,
						hasSubmitted: true,
					}));
				} // otherwise extend the array
				else
					props.setMySubmissions((prev) => {
						// check if loaded
						if (prev?.length === 0) return [];
						return [...prev, entry];
					});
				// update the main submissions
				props.setSubmissions((prev) => {
					if (prev?.length === 0) return [];
					return [...prev, entry];
				});
			}
		});
	};

	// restore the code from local storage
	useEffect(() => {
		if (
			!event?.submissions ||
			// check if the user has already submitted
			(!event?.submissions?.some(
				(sub) => sub?.author !== currentUserData?._id,
			) &&
				event?.allowedEntriesPerUser === 1)
		) {
			const storedSub: string | null =
				localStorage.getItem("levelSubmission");
			// make sure it exists
			if (!storedSub) return;
			// load the submission from localstorage
			const parsedSub: LevelSubmission = JSON.parse(storedSub);
			setSubmission(parsedSub);

			// validation
			const result = validateLevelCode(
				parsedSub?.content,
				parsedSub?.game,
				null,
				null,
			);
			if (parsedSub?.content) setValidCode(result?.isValid);
		}
	}, [event, currentUserData?._id]);

	// update the localstorage item if the submission exists
	useEffect(() => {
		if (!submission) return;
		if (!binaryContent)
			localStorage.setItem("levelSubmission", JSON.stringify(submission));
	}, [submission, binaryContent]);

	return (
		<>
			<Modal.Body className="modal-filter modal-bdy center-textoutput">
				<h1 className="center-textoutput">Submit an entry</h1>
				<hr />
				<div className="tab-container" style={{ marginBottom: "10px" }}>
					<button
						onClick={() => changeUploadType(false)}
						className={`tab-button ${!binaryContent && "active"}`}>
						Level code
					</button>
					<button
						onClick={() => changeUploadType(true)}
						className={`tab-button ${binaryContent && "active"}`}>
						Upload a file
					</button>
				</div>
				{binaryContent ? (
					<LevelFileUpload
						level={submission}
						setLevel={setSubmission}
						type={"event"}
						setLevelFileStatus={setValidCode}
					/>
				) : (
					<textarea
						className="form-control"
						style={{ resize: "none" }}
						id="code"
						maxLength={100000}
						rows={7}
						value={submission?.content || ""}
						onChange={(e) => {
							const target: HTMLTextAreaElement = e.target;
							const value: string = target.value;
							handleCodeChange(value);
						}}
					/>
				)}
				{validLevel && (
					<div
						style={{
							position: "relative",
							margin: "0 auto",
							marginTop: "10px",
						}}>
						<h2>Submission name</h2>
						<input
							type="text"
							className="form-control"
							id="name"
							placeholder="A name is required"
							maxLength={64}
							value={submission?.name}
							onChange={(e) => {
								setSubmission((prev) => ({
									...(prev ? prev : {}),
									name: e.target.value,
								}));
							}}
						/>
						<img
							src={game?.cleanLogo}
							alt={game?.name}
							className="event-game-logo"
						/>{" "}
					</div>
				)}
				{!event?.publicSubmissions && (
					<div
						className="yellow center-textoutput vibrant"
						style={{ marginTop: "10px" }}>
						<u>WARNING:</u> Submissions are and should stay private
						until the event ends.
						<br />
						Publishing outside of this event before the end will
						result in a disqualification.
					</div>
				)}
			</Modal.Body>
			<Modal.Footer className="modal-buttons modal-filter">
				<Button
					disabled={!validLevel || !submission?.name}
					className="btn-primary__special"
					onClick={() => handleSubmit()}>
					{validCode === false
						? "Invalid code"
						: !submission?.content
							? binaryContent
								? "Upload a level file"
								: "Enter a level code"
							: !submission?.name
								? "Enter a name"
								: "Submit"}
				</Button>
				<Button onClick={() => modalProps.setShowSubmitModal(false)}>
					Close
				</Button>
			</Modal.Footer>
		</>
	);
};

const EventInformation = ({ event }) => {
	const entryMax = event?.maximumEntriesTotal || "unlimited";
	const entryMaxDisplay =
		event?.limitEntryWeight || entryMax === "unlimited"
			? "participants"
			: "entries";
	const entryVisibilityMessage = event?.publicSubmissions
		? "always public"
		: "private as long as submissions are open";

	return (
		<ul
			style={{
				display: "flex",
				flexDirection: "column",
			}}>
			<li>
				Event visibility:{" "}
				<span className="yellow">{event?.visibility}</span>
			</li>
			<li>
				Maximum number of {entryMaxDisplay}:{" "}
				<span className="yellow">{entryMax}</span>
			</li>
			<li>
				Maximum entries per user:{" "}
				<span className="yellow">
					{event?.allowedEntriesPerUser || 1}
				</span>
			</li>
			<li>
				Entries are{" "}
				<span className="yellow">{entryVisibilityMessage}</span>.
			</li>
		</ul>
	);
};

export default Event;
